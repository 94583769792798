import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect, } from "react";
import styles from "./Thresholds.module.css";
import TuneIcon from "@mui/icons-material/Tune";
import CheckIcon from "@mui/icons-material/Check";
import { setTempEdit, setSbpEdit, setDbpEdit, setPrEdit, setSpo2Edit, setHrEdit, setRrEdit, setFev1Edit, setPefEdit, setSugarEdit, setPainEdit, setWeightEdit, setAllEdit, setShowConfirmationBanner, setDisableAllEditButton, getLatestBaseline, setHrvEdit, setValidationError } from "../../../../Actions/PatientActions";
import { getBaselineSelector, getDisableAllEditButton, getHasValidationError, getShowConfirmationBanner } from "../../../../Reducer/selectors/PatientSelector";
import ThresholdsParams from "./ThresholdsParams";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import { getCurrentPatientGUID, getThresholdsLoader } from "../../../../Reducer/selectors/UtilSelector";
import { CircularProgress } from "@material-ui/core";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { globalConstants } from "../../../../Constants/GlobalConstants";
import ThresholdsParamsV2 from "./ThresholdsParamsV2";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    marginTop: "8px !important"
  },
}));

const Thresholds = forwardRef((props, ref1) => {
  const dispatch = useDispatch();
  const ref = useRef();

  const [iconEditAllClicked, setIconEditAllClicked] = useState(true);

  const disableEditAll = useSelector(getDisableAllEditButton);

  const showConfrimationBanner = useSelector(getShowConfirmationBanner);

  const thresholdsLoader = useSelector(getThresholdsLoader);

  const thresholdsData = useSelector(getBaselineSelector);

  const hasValidationErrors = useSelector(getHasValidationError);

  const editAllThresholds = (b) => {
    dispatch(setAllEdit(b));
    dispatch(setTempEdit(b));
    dispatch(setSbpEdit(b));
    dispatch(setDbpEdit(b));
    dispatch(setPrEdit(b));
    dispatch(setSpo2Edit(b));
    dispatch(setHrEdit(b));
    dispatch(setHrvEdit(b));
    dispatch(setRrEdit(b));
    dispatch(setFev1Edit(b));
    dispatch(setPefEdit(b));
    dispatch(setSugarEdit(b));
    dispatch(setPainEdit(b));
    dispatch(setWeightEdit(b));
  };

  const toggleIcon = () => {
    editAllThresholds(!iconEditAllClicked);
    setIconEditAllClicked(!iconEditAllClicked);
  };

  const closeConfirmationBanner = () => {
    dispatch(setShowConfirmationBanner(false));
    editAllThresholds(true);
    setIconEditAllClicked(true);
    dispatch(setDisableAllEditButton(false));
    dispatch(setValidationError(false));
    ref.current?.setTrendStates();
    ref.current?.setAllIconClicked(true);
  }

  const submitNewThresholdAll = () => {
    dispatch(setShowConfirmationBanner(false));
    editAllThresholds(true);
    dispatch(setDisableAllEditButton(false));
    setIconEditAllClicked(true);
    ref.current?.submitNewThresholdFinal();
    ref.current?.setAllIconClicked(true);
  };

  const submitNewThreshold = (event) => {
    event.preventDefault();
    dispatch(setShowConfirmationBanner(true));
  };

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const timeZoneFromDayjs = dayjs.tz.guess();

  const setAllIconClickedFun = (payload) => {
    editAllThresholds(!iconEditAllClicked);
    setIconEditAllClicked(!iconEditAllClicked);
    ref.current?.setAllIconClicked(true);
    ref.current?.setNewTrendsWPaylaod(payload);
  }

  useImperativeHandle(ref1, () => {
    return {
      setAllIconClickedFun: setAllIconClickedFun,
      closeConfirmationBanner: closeConfirmationBanner
    };
  });

  const userRole = useSelector(getUserRole);

  useEffect(() => {
    if (userRole == globalConstants.USER_ROLE_CARE_MANAGER) {
      dispatch(setDisableAllEditButton(true));
    }
  }, [userRole]);

  return (
    <div className={styles.rootDiv}>
      <div className={styles.responsive}>
        <table className={styles.cardStyle}>
          <colgroup>
            <col class={styles.w}></col>
            <col class={styles.w1}></col>
            <col class={styles.w1}></col>
            <col class={styles.w1}></col>
            <col className={styles.w2}></col>
          </colgroup>
          <thead>
            <tr className={styles.header}>
              <th className={styles.headerText}>Vitals</th>
              <th className={styles.headerText}>Low</th>
              <th className={styles.headerText}>Baseline</th>
              <th className={styles.headerText}>High</th>
              <th className={styles.headerIcon}>
                {!disableEditAll && (
                  showConfrimationBanner ? (
                    <></>
                  ) : (
                    iconEditAllClicked ? (
                      <div className={styles.iconEditAll} onClick={() => toggleIcon()}>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <div className={styles.tootlTipHeading}>Edit All</div>
                            </React.Fragment>
                          }
                        >
                          <TuneIcon style={{ height: "15px", width: "15px" }} />
                        </HtmlTooltip>
                      </div>
                    ) : (
                      <div className={styles.editOptions2}>
                        <div className={styles.editOptions}>
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <div className={styles.tootlTipHeading}>
                                  {hasValidationErrors ? "Resolve errors" : "Confirm"}
                                </div>
                              </React.Fragment>
                            }
                          >
                            <CheckIcon
                              style={{
                                height: "15px",
                                width: "15px",
                                cursor: hasValidationErrors ? "not-allowed" : "pointer",
                                opacity: hasValidationErrors ? 0.5 : 1,
                              }}
                              onClick={(event) => {
                                if (!hasValidationErrors) {
                                  submitNewThreshold(event);
                                }
                              }}
                            />
                          </HtmlTooltip>
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <div className={styles.tootlTipHeading}>Cancel</div>
                              </React.Fragment>
                            }
                          >
                            <ClearIcon
                              style={{ height: "15px", width: "15px" }}
                              onClick={() => closeConfirmationBanner()}
                            />
                          </HtmlTooltip>
                        </div>
                      </div>
                    )
                  )
                )}
              </th>

            </tr>
          </thead>
        </table>
        {thresholdsLoader ? (<div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFFFFF`, height: `100%`, width: `100%` }}>
          <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}></CircularProgress>
        </div>) : (<ThresholdsParamsV2 ref={ref} />)}
      </div>
      {
        showConfrimationBanner ?
          (<>
            <div className={styles.promptDiv}>
              <div className={styles.newBaselinePrompt}>
                <div className={styles.promptMessage}>
                  Do you want to confirm the newly selected thresholds?
                </div>
                <div className={styles.noButton} onClick={() => closeConfirmationBanner()}>
                  No <CloseIcon style={{ height: "20px", width: "20px" }}></CloseIcon>
                </div>
                <div className={styles.yesButton} onClick={() => submitNewThresholdAll()}>
                  Yes <CheckIcon style={{ height: "20px", width: "20px" }}></CheckIcon>
                </div>
              </div>
            </div>
          </>) : thresholdsData.dateCreated ? (<>
            <div className={styles.promptDiv}>
              <div className={styles.newBaselinePrompt}>
                <div className={styles.promptMessage}>
                  Last thresholds updated on: {thresholdsData.dateCreated} by {thresholdsData.userFirstName + ' ' + thresholdsData.userLastName}
                </div>
              </div>
            </div>
          </>) : (<></>)
      }
    </div >
  );
});

export default Thresholds;
