import React, { useState } from "react";
import styles from "../../Authentication/SignUp.module.css";
import UserPool from "../../Authentication/UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";
import { NavLink, useNavigate } from "react-router-dom";
import { MuiOtpInput } from 'mui-one-time-password-input';
import PostSignUpUserToDB from "../../API/PostSignUpUserToDB/PostSignUpUserToDB";
import Select from "react-select";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from "@material-ui/core/CircularProgress";

function SignupForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState({});
  const [lastName, setLastName] = useState({});
  const [profession, setProfession] = useState({});
  const [speciality, setSpeciality] = useState('');
  const [middleInitial, setMiddleInitial] = useState({});
  const [salutation, setSalutation] = useState({});
  const [gender, setGender] = useState({ Name: '', Value: '' });
  const [npiNumber, setNpiNumber] = useState({});
  const [mobileNumber, setMobileNumber] = useState({});
  const [officeNumber, setOfficeNumber] = useState({});
  const [isUserAdded, setIsUserAdded] = useState(0);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSignUp, setIsSignUp] = useState(true);
  const [isVerify, setIsVerify] = useState(false);
  const [privacyCheckbox, setPrivacyCheckbox] = useState(false);
  const [textMsgCheckbox, setTextMsgCheckbox] = useState(false);

  const navigate = useNavigate();

  // State to manage unconfirmed users
  const [isUserConfirmed, setIsUserConfirmed] = useState(false);

  // state to handle signed up errors
  const [signUpError, setSignUpError] = useState("");
  // state to handle error displaying in signing up process
  const [isError, setIsError] = useState(false);

  // State to manage unverified users
  const [isUserVerified, setIsUserVerified] = useState(false);

  // state to handle verified up errors
  const [verificationError, setVerificationError] = useState("");

  // state to handle error displaying in signing up process
  const [isVerificationError, setIsVerificationError] = useState(false);

  // state to handle the resend confirmation code
  const [isResendCode, setIsResendCode] = useState(false);

  // Sign up button control state
  const [isButtonActive, setIsButtonActive] = useState(true);

  // To store OTP value
  const [otpValue, setOtpValue] = React.useState('');

  // Flag to submit OTP
  const [isOTPFull, setIsOTPFull] = React.useState(false);

  //this is a list of the user info
  var attributeList = [];

  const salutations = [{ id: "0", value: "", label: "None" }, { id: "1", value: "Dr.", label: "Dr." }, { id: "2", value: "Mr.", label: "Mr." }, { id: "3", value: "Mrs.", label: "Mrs." }, { id: "4", value: "Ms.", label: "Ms." }];

  const genders = [ { id: "0", value: "Male", label: "Male" }, { id: "1", value: "Female", label: "Female" }, { id: "2", value: "Other", label: "Other" }];

  const [isSignUpGoingOn, setIsSignUpGoingOn] = React.useState(false);

  const [passwordError, setPasswordError] = React.useState('');

  const [emailError, setEmailError] = React.useState(false);

  //onsubmit/ sign up button handler
  const onSubmit = async (event) => {

    event.preventDefault();

    console.log('gender selection: ', gender);

    if (gender.value === '' || !privacyCheckbox) {
      setIsError(true);
      setSignUpError('Please fill out all the necessary fields');
    } else {

      attributeList.push(firstName);
      attributeList.push(lastName);
      attributeList.push(salutation);
      attributeList.push(middleInitial);
      attributeList.push(npiNumber);

      await UserPool.signUp(email, password, [], null, (err, data) => {

        if (err) {
          setIsError(true);
          setSignUpError(err.message);
          setIsUserConfirmed(false);
          setIsButtonActive(true);

          console.error("This is error", err);
        } else {
          setIsError(false);
          setIsUserConfirmed(true);
          addUserToDB();
          setIsSignUp(false);
          setIsVerify(true);

        }
      });
    }
  };

  const user = {
    email: email,
    salutation: salutation.Value,
    firstName: firstName.Value,
    lastName: lastName.Value,
    npi: npiNumber.Value,
    gender: gender.Value,
    profession: profession.Value,
    speciality: speciality,
    mobileNo: '+1' + mobileNumber.Value,
    officeNo: officeNumber.Value,
    notifyMe: textMsgCheckbox,
    privacyAccept: privacyCheckbox,
  };

  const verifyCode = async (event) => {
    console.log('verifyCode');
    event.preventDefault();
    setIsSignUpGoingOn(true);
    setIsVerify(true);
    setIsResendCode(false);

    const userData = {
      Username: email,
      Pool: UserPool
    }

    const cognitoUser = new CognitoUser(userData);
    console.log("OTP val: ", otpValue);

    await cognitoUser.confirmRegistration(otpValue, false, function (err, result) {
      if (err) {
        setIsVerificationError(true);
        setVerificationError(err.message);
        setIsUserVerified(false);
        setIsSignUpGoingOn(false);
        return;
      } else {
        setIsUserVerified(true);
        setIsVerificationError(false);
        setTimeout(() => {
          navigate(`/authentication`);
        }, 2000);
      }
    });

  }

  const resendConfirmationCode = async (event) => {
    event.preventDefault();
    const userData = {
      Username: email,
      Pool: UserPool
    }
    const cognitoUser = new CognitoUser(userData);
    await cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        console.log(err);
        console.log("Re-send unsuccessful");
        setVerificationError(err.message);
        return;
      } else {
        setVerificationError(false);
        setIsUserVerified(false);
        setIsResendCode(true);
        //console.log(result);
        //console.log("Resend successful");
      }
    });
  }

  const addUserToDB = () => {
    PostSignUpUserToDB(user).then((res) => {
      //console.log("I got the response ", res);
      setResponseMessage(res.message);
      if (res.operationSuccessful === true) {
        setIsUserAdded(1);
      } else {
        setIsUserAdded(0);
      }
    });
  };

  const checkOTPLength = () => {
    if (otpValue.length === 6) {
      setIsOTPFull(true);
    } else {
      setIsOTPFull(false);
    }
  }

  const handleOtpChange = (newValue) => {
    checkOTPLength();
    setOtpValue(newValue);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value.trim());
  }

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value.trim());
  }

  const handleValidation = (event) => {
    const pwdInput = event.target.value.trim();

    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;

    const pwdLength = pwdInput.length;

    const uppercasePassword = uppercaseRegExp.test(pwdInput);
    const lowercasePassword = lowercaseRegExp.test(pwdInput);
    const digitsPassword = digitsRegExp.test(pwdInput);
    const specialCharPassword = specialCharRegExp.test(pwdInput);
    const minLengthPassword = minLengthRegExp.test(pwdInput);

    let errMsg = "";
    if (!uppercasePassword) {
      errMsg = "Needs at least 1 upper case character";
    } else if (!lowercasePassword) {
      errMsg = "Needs at least 1 lower case character";
    } else if (!digitsPassword) {
      errMsg = "Needs at least 1 numerical digit";
    } else if (!specialCharPassword) {
      errMsg = "Needs at least 1 special character";
    } else if (!minLengthPassword) {
      errMsg = "Needs at least 8 characters in total";
    } else {
      errMsg = "";
    }
    setPasswordError(errMsg);
  }

  const handleNPIValidation = (event) => {

    setNpiNumber({
      Name: "custom:NpiNumber",
      Value: event.target.value,
    });

    if (event.target.value > 0 || !event.target.value) {
      event.target.setCustomValidity('');
    }
  }

  const handleMobileNumberValidation = (event) => {

    setMobileNumber({
      Name: "custom:MobileNumber",
      Value: event.target.value,
    });

    if (event.target.value > 0 || !event.target.value) {
      event.target.setCustomValidity('');
    }
  }

  const handleOfficeNumberValidation = (event) => {

    setOfficeNumber({
      Name: "custom:OfficeNumber",
      Value: event.target.value,
    });

    if (event.target.value > 0 || !event.target.value) {
      event.target.setCustomValidity('');
    }
  }

  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  }

  const handleEmailValidation = (event) => {
    event.preventDefault();
    //console.log('email address:', email);
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailValid = regexp.test(email);
    if (!isEmailValid && email) {
      setEmailError(true);
      setIsButtonActive(false);
    } else {
      setEmailError(false);
      setIsButtonActive(true);
    }
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const handleCancelSignup = (e) => {
    e.preventDefault();
    navigate(`/authentication`);
  }

  const handlePrivacyCheckboxChange = (event) => {
    setPrivacyCheckbox(event.target.checked);
  };

  const handleTextMsgCheckboxChange = (event) => {
    setTextMsgCheckbox(event.target.checked);
  };

  const handleGenderChange = (event) => {
    setGender({
      Name: "custom:Gender",
      Value: event.value,
    });
    console.log('new gender val: ', gender);
  };

  return (
    <>
      {isSignUp &&
        <div className={styles.loginContainer1}>
          <div className={styles.openMessage}>Sign up</div>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className={styles.FormContainer}>
              <div className={styles.containerPt1}>

                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStyles} htmlFor="salutation">
                      Salutation
                    </label>
                  </div>
                  <div className={styles.inputDropDown}>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#793f98' : '#ced4da',
                          borderRadius: '50px'
                        }),
                      }}
                      defaultValue={salutations.at(0).value}
                      onChange={(event) => setSalutation({
                        Name: "custom:Salutation",
                        Value: event.value,
                      })}
                      options={salutations}
                    />
                  </div>
                </div>

                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStylesRequired} htmlFor="firstName">
                      First Name
                    </label>
                  </div>
                  <input
                    className={styles.inputStyle}
                    type="text"
                    required
                    onChange={(event) => setFirstName({
                      Name: "custom:FirstName",
                      Value: event.target.value,
                    })}
                  ></input>
                </div>

                {/* <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStyles} htmlFor="middleInitial">
                      Middle Initial
                    </label>
                  </div>
                  <input
                    className={styles.inputStyle}
                    type="text"
                    onChange={(event) => setMiddleInitial({
                      Name: "custom:MiddleInitial",
                      Value: event.target.value,
                    })}
                  ></input>
                </div> */}

                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStylesRequired} htmlFor="lastName">
                      Last Name
                    </label>
                  </div>
                  <input
                    className={styles.inputStyle}
                    type="text"
                    required
                    onChange={(event) => setLastName({
                      Name: "custom:LastName",
                      Value: event.target.value,
                    })}
                  ></input>
                </div>

                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStylesRequired} htmlFor="salutation">
                      Gender
                    </label>
                  </div>
                  <div className={styles.inputDropDown}>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#793f98' : '#ced4da',
                          borderRadius: '50px'
                        }),
                      }}
                      defaultValue={genders.at(0).value}
                      onChange={(event) => handleGenderChange(event)}
                      options={genders}
                    />
                  </div>
                </div>

                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStylesRequired} htmlFor="profession">
                      Profession
                    </label>
                  </div>
                  <input
                    className={styles.inputStyle}
                    type="text"
                    required
                    onChange={(event) => setProfession({
                      Name: "custom:Profession",
                      Value: event.target.value,
                    })}
                  ></input>
                </div>


                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStyles}>
                      Speciality
                    </label>
                  </div>
                  <input
                    className={styles.inputStyle}
                    type="text"
                    required={false}
                    onChange={(event) => setSpeciality(event.target.value)}
                  ></input>
                </div>


              </div>


              <div className={styles.containerPt1}>

                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStyles} htmlFor="npiNumber">
                      NPI Number
                    </label>
                  </div>
                  <input
                    className={styles.inputStyle}
                    type="number"
                    min="0"
                    onInvalid={e => e.target.setCustomValidity('Please enter a valid NPI')}
                    onChange={(event) => handleNPIValidation(event)}
                    aria-describedby="component-helper-text"
                    onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()}
                  ></input>
                </div>

                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStylesRequired} htmlFor="mobileNumber">
                      Mobile Number
                    </label>
                  </div>
                  <input
                    className={styles.inputStyle}
                    required
                    type="number"
                    min="1111111111"
                    max="9999999999"
                    onInvalid={e => e.target.setCustomValidity('Please enter a valid mobile number')}
                    onChange={(event) => handleMobileNumberValidation(event)}
                    aria-describedby="component-helper-text"
                    onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()}
                  ></input>
                </div>


                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStyles} htmlFor="officeNumber">
                      Office Number
                    </label>
                  </div>
                  <input
                    className={styles.inputStyle}
                    type="number"
                    min="0"
                    onInvalid={e => e.target.setCustomValidity('Please enter a valid office number')}
                    onChange={(event) => handleOfficeNumberValidation(event)}
                    aria-describedby="component-helper-text"
                    onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()}
                  ></input>
                </div>


                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStylesRequired} htmlFor="email">
                      Email
                    </label>
                  </div>
                  <input
                    className={styles.inputStyle}
                    type="email"
                    value={email}
                    placeholder="user@example.com"
                    required
                    onChange={(event) => handleEmailChange(event)}
                    onKeyUp={(e) => handleEmailValidation(e)}
                  ></input>
                </div>
                {emailError && (
                  <div className={styles.formFieldWrapper}>
                    <div className={styles.labelWrapper}>
                      <label className={styles.errorMessageStyles} htmlFor="error">
                        Enter a valid email address
                      </label>
                    </div>
                  </div>
                )}
                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStylesRequired} htmlFor="password">
                      Password
                    </label>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <h4 className={styles.infoText}>The password requires a minimum of:</h4>
                          <ul>
                            <li className={styles.infoText}>8 characters total</li>
                            <li className={styles.infoText}>1 upper case character [A-Z]</li>
                            <li className={styles.infoText}>1 lower case character [a-z]</li>
                            <li className={styles.infoText}>1 numerical digit [0-9]</li>
                            <li className={styles.infoText}>1 special character [!@#$%^&*()]</li>
                          </ul>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon className={styles.infoButton}></InfoOutlinedIcon>
                    </HtmlTooltip>
                  </div>
                  <input className={styles.inputStyle} type="password" value={password} onChange={(e) => handlePasswordChange(e)} onKeyUp={(e) => handleValidation(e)}></input>
                  <p className={styles.errorMessageStyles}>{passwordError}</p>
                </div>
                <div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelStylesRequired} htmlFor="confirmPassword">
                      Confirm Password
                    </label>
                  </div>
                  <input className={styles.inputStyle} type="password" value={confirmPassword} onChange={(e) => handleConfirmPasswordChange(e)}></input>
                </div>

                {password !== confirmPassword && (
                  <div className={styles.formFieldWrapper}>
                    <div className={styles.labelWrapper}>
                      <label className={styles.errorMessageStyles} htmlFor="error">
                        Passwords do not match
                      </label>
                    </div>
                  </div>
                )}

              </div>



            </div>
            {isError && (
              <div className={styles.formFieldWrapper}>
                <div className={styles.labelWrapper}>
                  <label className={styles.errorMessageStyles1} htmlFor="error">
                    {signUpError}
                  </label>
                </div>
              </div>
            )}

            {isUserConfirmed && (
              <div className={styles.formFieldWrapper}>
                <div className={styles.labelWrapper}>
                  <label className={styles.successMessageStyle} htmlFor="success">
                    Sign up is successful, Please wait for Aidar to confirm your email id.
                  </label>
                </div>
              </div>
            )}

            <div className={styles.consentDiv}>
              <div className={styles.checkBoxDiv}><Checkbox value={privacyCheckbox} onChange={handlePrivacyCheckboxChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} /></div>
              <div className={styles.checkBoxLabel}>I agree to Terms & Conditions and Privacy Policy*.</div>
            </div>

            <div className={styles.consentDiv1}>
              <div className={styles.checkBoxDiv}><Checkbox value={textMsgCheckbox} onChange={handleTextMsgCheckboxChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} /></div>
              <div className={styles.checkBoxLabel}>I agree to receive messages from Aidar Health at the provided number.</div>
            </div>

            <div className={styles.buttonDiv}>
              <button className={styles.loginButton} type="submit" disabled={!isButtonActive || (password !== confirmPassword)}>
                Sign Up
              </button>
            </div>

            <div className={styles.buttonDiv3}>
              Already have an account?{" "}
              <span>
                <NavLink to="/authentication" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#AA346F' }} className={styles.navlinkStyle}>
                  Log in
                </NavLink>
              </span>
            </div>

          </form>
        </div>
      }

      {isVerify &&
        <div className={styles.loginContainer}>
          <div className={styles.openMessage}> Complete your sign in</div>
          <form>
            <div className={styles.FormContainer1}>
              <div className={styles.formFieldWrapper}>
                <div className={styles.labelWrapper}>
                  <label fontColor="black" font-size="18px" font-family="GTWalsheimProRegular">
                    Enter the 6-digit code that was sent to your email*
                  </label>
                </div>
                <MuiOtpInput length={6} value={otpValue} onChange={handleOtpChange} />
              </div>

              {isVerificationError &&
                (<div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.errorMessageStyles} htmlFor="error">
                      {verificationError}
                    </label>
                  </div>
                </div>)
              }

              {isUserVerified &&
                (<div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.errorMessageStyles} htmlFor="error">
                      You've been successfully verified! Please wait to be redirected to login page.
                    </label>
                  </div>
                </div>)
              }

              {isResendCode &&
                (<div className={styles.formFieldWrapper}>
                  <div className={styles.labelWrapper}>
                    <label className={styles.errorMessageStyles} htmlFor="error">
                      A new code has been sent to your email.
                    </label>
                  </div>
                </div>)
              }

              {isSignUpGoingOn ?
                (<>
                  <div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFEFF6`, height: `100%`, width: `100%`, display: 'flex', alignItems: 'centre', justifyContent: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
                    <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}></CircularProgress>
                  </div>
                </>) :
                (<>
                  <div className={styles.buttonDiv}>
                    <button className={styles.loginButton} type="submit" style={{ cursor: 'pointer' }} onClick={e => verifyCode(e)}>
                      Submit
                    </button>
                  </div>
                  <div className={styles.buttonDiv}>
                    <span>
                      <p className={styles.navlinkStyle} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#AA346F' }} onClick={e => resendConfirmationCode(e)}>
                        Resend Verification Code
                      </p>
                    </span>
                  </div>
                  <div className={styles.buttonDiv1}>
                    <p className={styles.navlinkStyle} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#AA346F' }} onClick={e => handleCancelSignup(e)}>
                      Cancel
                    </p>
                  </div>
                </>)
              }

            </div>
          </form>
        </div>
      }
    </>
  );
}

export default SignupForm;
