import React, { useEffect, useRef, useState } from "react";
import styles from "./Thresholds.module.css";
import TuneIcon from "@mui/icons-material/Tune";
import { makeStyles } from "@material-ui/core/styles";
import Thresholds from "./Thresholds";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { getBaselineSelector } from "../../../../Reducer/selectors/PatientSelector";
import { useSelector } from "react-redux";
import Select from "react-select";


const useStyles = makeStyles(() => ({
  tooltip: {
    marginTop: 5,
    font: "12px",
    background: "black",
    fontFamily: "GTWalsheimProRegular",
  },
}));


export default function ThresholdsWrapper() {
  const classes = useStyles();
  const ref1 = useRef();

  const [curThresholdSelection, setCurThresholdSelection] = useState('');
  const [filterName, setFilterName] = useState('Custom');
  const [selectedOptions, setSelectedOptions] = useState({value:"Custom",label:"Custom"});

  const subjectThresholdsData = useSelector(getBaselineSelector);

  const checkIfItsDefault = () => {
    let payload = {
      breathingRateHigh: 20,
      breathingRateLow: 10,
      breathingRateValue: 13,
      dbpHigh: 85,
      dbpLow: 58,
      dbpValue: 65,
      fev1Low: 2.1,
      fev1Value: 2.5,
      fev1High: 4.5,
      heartRateHigh: 90,
      heartRateLow: 50,
      heartRateValue: 60,
      hrvHigh: 120,
      hrvLow: 45,
      hrvValue: 55,
      painHigh: 3,
      painLow: 0,
      painValue: 0,
      pefLow: 3.8,
      pefValue: 4.5,
      pefHigh: 6,
      pulseRateHigh: 90,
      pulseRateLow: 50,
      pulseRateValue: 60,
      sbpHigh: 150,
      sbpLow: 90,
      sbpValue: 100,
      spo2High: 100,
      spo2Low: 92,
      spo2Value: 95,
      sugarHigh: 120,
      sugarLow: 70,
      sugarValue: 80,
      tempHigh: 98.9,
      tempLow: 95,
      tempValue: 96,
      weightHigh: 180,
      weightLow: 100,
      weightValue: 120,
    };

    if (subjectThresholdsData.breathingRateHigh === payload.breathingRateHigh &&
      subjectThresholdsData.breathingRateLow === payload.breathingRateLow &&
      subjectThresholdsData.breathingRateValue === payload.breathingRateValue &&
      subjectThresholdsData.dbpHigh === payload.dbpHigh &&
      subjectThresholdsData.dbpLow === payload.dbpLow &&
      subjectThresholdsData.dbpValue === payload.dbpValue &&
      subjectThresholdsData.fev1Low === payload.fev1Low &&
      subjectThresholdsData.fev1High === payload.fev1High &&
      subjectThresholdsData.fev1Value === payload.fev1Value &&
      subjectThresholdsData.heartRateHigh === payload.heartRateHigh &&
      subjectThresholdsData.heartRateLow === payload.heartRateLow &&
      subjectThresholdsData.heartRateValue === payload.heartRateValue &&
      subjectThresholdsData.hrvHigh === payload.hrvHigh &&
      subjectThresholdsData.hrvLow === payload.hrvLow &&
      subjectThresholdsData.hrvValue === payload.hrvValue &&
      subjectThresholdsData.painHigh === payload.painHigh &&
      subjectThresholdsData.painLow === payload.painLow &&
      subjectThresholdsData.painValue === payload.painValue &&
      subjectThresholdsData.pefLow === payload.pefLow &&
      subjectThresholdsData.pefHigh === payload.pefHigh &&
      subjectThresholdsData.pefValue === payload.pefValue &&
      subjectThresholdsData.pulseRateHigh === payload.pulseRateHigh &&
      subjectThresholdsData.pulseRateLow === payload.pulseRateLow &&
      subjectThresholdsData.pulseRateValue === payload.pulseRateValue &&
      subjectThresholdsData.sbpHigh === payload.sbpHigh &&
      subjectThresholdsData.sbpLow === payload.sbpLow &&
      subjectThresholdsData.sbpValue === payload.sbpValue &&
      subjectThresholdsData.spo2High === payload.spo2High &&
      subjectThresholdsData.spo2Low === payload.spo2Low &&
      subjectThresholdsData.spo2Value === payload.spo2Value &&
      subjectThresholdsData.sugarHigh === payload.sugarHigh &&
      subjectThresholdsData.sugarLow === payload.sugarLow &&
      subjectThresholdsData.sugarValue === payload.sugarValue &&
      subjectThresholdsData.tempHigh === payload.tempHigh &&
      subjectThresholdsData.tempLow === payload.tempLow &&
      subjectThresholdsData.tempValue === payload.tempValue &&
      subjectThresholdsData.weightHigh === payload.weightHigh &&
      subjectThresholdsData.weightLow === payload.weightLow &&
      subjectThresholdsData.weightValue === payload.weightValue) {
      setFilterName('Default');
      setCurThresholdSelection('Default')
    } else {
      setFilterName('Custom');
      setCurThresholdSelection('');
    }
  };

  useEffect(() => {
    checkIfItsDefault();
  }, [subjectThresholdsData]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "12px",
      borderColor: "#ccc",
      display: "flex",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "max-content",
      fontFamily: "GTWalsheimProRegular",
      maxHeight:"100%",
      margin: "1px",
      fontSize: "14px",
      minHeight: "auto !important",
      transform: "scale(0.9)",
      cursor: "pointer"
    }),
    option: (provided, { data, isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected
        ? data.color
        : isFocused
        ? "#f0f0f0"
        : "white",
      color: "#333",
      fontWeight: isSelected ? "bold" : "normal",
      fontSize: "14px",
      transform: "scale(0.9)",
      cursor: "pointer"
    }),
    valueContainer: (provided) => ({
      ...provided,
      overflow: "hidden",
      display: "flex",
      whiteSpace: "nowrap",
      marginLeft:"auto",
      padding: "0 8px",
    }),
    container: (provided) => ({
      ...provided,
      marginLeft: "auto",
    }),
  
  };

  const updateThresholdSelection = (newSelection) => {
    setCurThresholdSelection(newSelection);
    let payload = {
      breathingRateHigh: '20',
      breathingRateLow: '10',
      breathingRateValue: '13',
      dbpHigh: '85',
      dbpLow: '58',
      dbpValue: '65',
      fev1Low: '2.1',
      fev1Value: '2.5',
      fev1High: '4.5',
      heartRateHigh: '90',
      heartRateLow: '50',
      heartRateValue: '60',
      hrvHigh: '120',
      hrvLow: '45',
      hrvValue: '55',
      painHigh: '3',
      painLow: '0',
      painValue: '0',
      pefLow: '3.8',
      pefValue: '4.5',
      pefHigh: '6',
      pulseRateHigh: '90',
      pulseRateLow: '50',
      pulseRateValue: '60',
      sbpHigh: '150',
      sbpLow: '90',
      sbpValue: '100',
      spo2High: '100',
      spo2Low: '92',
      spo2Value: '95',
      sugarHigh: '120',
      sugarLow: '70',
      sugarValue: '80',
      tempHigh: '98.9',
      tempLow: '95',
      tempValue: '96',
      weightHigh: '180',
      weightLow: '100',
      weightValue: '120',
    };
    ref1.current?.setAllIconClickedFun(payload);
    setFilterName('Default');
  };
  const handleChange=(event)=>{
    if (event?.value === "Default") {
      updateThresholdSelection('Default')
    }
    else if (event?.value === "Custom") {
      ref1.current?.closeConfirmationBanner();
    }
    setSelectedOptions(event)

  }

  const RenderSelections = (props) => {
    return (
      <FormGroup sx={{ marginTop: '10px' }}>
        <FormControlLabel className={curThresholdSelection === 'Default' ? styles.filtersDivTextSelectedActive : styles.filtersDivTextActive} control={<Radio size="small" sx={{ color: "#1EF793", '&.Mui-checked': { color: "#1EF793" } }} name={'Default'} checked={true} onClick={(event) => updateThresholdSelection('Default', event, props.popupState)} />} label={'Default'} />
      </FormGroup>
    );
  };

  const SelectionPanel = (props) => {
    return (
      <div className={styles.wrapper}>
        <RenderSelections popupState={props.popupState}></RenderSelections>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.iconDiv}>
      <TuneIcon className={styles.iconDivSvg}></TuneIcon>
      <Select
           options={[{value:"Default",label:"Default"},{value:"Custom",label:"Custom"}]}
           value={selectedOptions}
           onChange={handleChange}
           styles={customStyles}
           placeholder="Select status"
         />
      </div>
      <div className={styles.tableContainerStyles}>
        <Thresholds ref={ref1} />
      </div>
    </div>
  );
}
