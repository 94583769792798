import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import AIDIGraphV2 from "./Graphs/RenderAIDIGraphV2";
import styles from "./AIDIGraphWrapper.module.css";
import { Paper, IconButton, Typography, Button, CircularProgress } from '@mui/material';
import SupportIcon from '../../../../Assets/icons/Support-Icon.svg';
import HeartBeat from '../../../../Assets/icons/Heart-Beat.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import AddEditNotes from "../AddEditNotes/AddEditNotes";
import { getCurrentPatientSubjectID } from "../../../../Reducer/selectors/UtilSelector";
import GetPatientInfo from "../../../API/GetPatientInfo/GetPatientInfo";
import { getUserProfile, getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { globalConstants } from "../../../../Constants/GlobalConstants";
import { postPatientNotes } from "../../../../Actions";

export default function AIDIGraphWrapperV2() {
    const [isExpandedView, setIsExpandedView] = useState(true);
    const { aidiGraphPayloadV2, loading } = useSelector((state) => state.aidiReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [disableTrainingReqdFlag, setDisableTrainingReqdFlag] = useState(false);
    const [openAddEditNote , setOpenAddEditNote]= useState(false);
    const [patientDetailInfo, setPatientDetailInfo] = useState({});

    const currPatientId = useSelector(getCurrentPatientSubjectID);
    const userRole = useSelector(getUserRole);
    const userId = useSelector(getUserProfile).id;
    
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    useEffect(() => {
        GetPatientInfo(currPatientId).then((res) => {
            setPatientDetailInfo(res);
        });
    }, [currPatientId]);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} sx={{ height: "20px", width: "20px" }} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#ffffff',
            color: 'rgba(0, 0, 0, 0.87)',
            width: '120px',
            fontSize: theme.typography.pxToRem(10),
            border: '1px solid #dadde9',
            borderRadius: '8px',
            textAlign: 'center',
            padding: 0,
        },
    }));

    const generateAidiLegends = (regions) => {
        return regions.map((region, index) => (
            <div key={index} className={`${styles.baselineLegendDiv} ${styles.baselineLegendItem}`}>
                <div
                    className={styles.legendLabel}
                    style={{ backgroundColor: region.color }}
                >
                    {`B${index + 1}`}
                </div>
                <span style={{ marginRight: '8px' }}>{region.name}</span>
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <div
                                className={styles.tooltipHeader}
                                style={{ backgroundColor: region.color }}
                            >
                                {region.name}
                            </div>
                            <div className={styles.tooltipSubHeader}>Key Parameters</div>
                            <hr className={styles.tooltipDivider} />
                            {region.keyParams.map((param, paramIndex) => (
                                <div key={paramIndex} className={styles.tooltipParamItem}>
                                    <div className={styles.tooltipParamNumber}>
                                        {paramIndex + 1}
                                    </div>
                                    <span className={styles.tooltipParamText}>{param}</span>
                                </div>
                            ))}
                        </React.Fragment>
                    }
                >
                    <InfoOutlinedIcon
                        className={styles.infoIcon}
                        style={{ color: region.color }}
                    />
                </HtmlTooltip>
            </div>
        ));
    };

    const onAddNote = (data) => {
        const { newNotes, isBookmarked, tagName ,mode } = data;

        if (newNotes.length <= 0 || newNotes.length > 5000 ||((userRole === globalConstants.USER_ROLE_AIDAR_ADMIN || userRole === globalConstants.USER_ROLE_BILLING_PHYSICIAN) && tagName === "")) {
            return;
        };

        const payload = {
            subjectId: currPatientId,
            createdBy: userId,
            note: newNotes,
            isBookmarked: isBookmarked  === false ? 0 : 1,
            tags: tagName,
            createdDateTime: new Date(),
            timezoneStr: timeZone,
            userRole: userRole,
        }
        dispatch(postPatientNotes(payload));
    }


    return (
        <div className={styles.aidiGraphWrapper}>
            <div className={styles.containerHeader}>
                <div className={styles.aidiGraphHeadingDiv}>
                    <div className={styles.aidiIconWrapper}>
                        <GraphicEqIcon style={{ color: "#4E253A", height: "18px", width: "18px" }} />
                    </div>
                    Aidar Index (AIDI)
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <div className={styles.tootlTipHeading}>Aidar Index</div>
                                <div className={styles.circlesList}>
                                    <div className={styles.circlesListItem}>
                                        <div className={styles.greenCircle}></div> 0.60 - 1.00
                                    </div>
                                    <div className={styles.circlesListItem}>
                                        <div className={styles.orangeCircle}></div> 0.30 - 0.59
                                    </div>
                                    <div className={styles.circlesListItem}>
                                        <div className={styles.redCircle}></div> 0.00 - 0.29
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    >
                        <InfoOutlinedIcon
                            className={styles.headerInfoIcon}
                            color="#ffffff"
                        />
                    </HtmlTooltip>
                </div>
                <div className={styles.expandAidiButton}>
                    <IconButton
                        className={styles.expandButton}
                        onClick={() => setIsExpandedView(!isExpandedView)}
                    >
                        <div className={styles.expandIconWrapper}>
                            {isExpandedView ? (
                                <KeyboardArrowUpIcon className={styles.arrowIcon} />
                            ) : (
                                <KeyboardArrowDownIcon className={styles.arrowIcon} />
                            )}
                        </div>
                    </IconButton>
                </div>
            </div>
            {isExpandedView && (
                <div className={styles.graphDivV2}>
                    {loading ? (
                        <div className={styles.loadingWrapper}>
                            <CircularProgress sx={{ color: "#4E253A" }} />
                        </div>
                    ) : aidiGraphPayloadV2?.chartData ? (
                        (aidiGraphPayloadV2?.chartData?.noData || disableTrainingReqdFlag) ? (
                            <div className={styles.noDataWrapper}>
                                <div className={styles.noDataMessage}>
                                    <img src={HeartBeat} className={styles.heartBeatIcon} />
                                    <div className={styles.noDataMessageText}>No AIDI to Display</div>
                                </div>
                            </div>
                        ) : (aidiGraphPayloadV2?.chartData?.trainingRequired && !disableTrainingReqdFlag) ? (
                            <div className={styles.noDataWrapper}>
                                <div className={styles.retrainDiv}>
                                    <div className={styles.retrainHeader}>
                                        <div>
                                        </div>
                                        <div className={styles.retrainHeaderText}>
                                            Patient Training Required
                                        </div>
                                        <div className={styles.retrainHeaderClose}>
                                            <CloseIcon sx={{ height: "15px", width: "15px" }} onClick={() => { setDisableTrainingReqdFlag(true) }} />
                                        </div>
                                    </div>
                                    <div className={styles.retrainBody}>
                                        <div className={styles.retrainBodyText}>
                                            <div>
                                                Please ensure the patient is taking measurements correctly.
                                            </div>
                                        </div>
                                        <div className={styles.retrainBodyImg}>
                                            <img src={SupportIcon} className={styles.supportIcon} />
                                        </div>
                                        <div className={styles.retrainBodyButtons}>
                                            <div className={styles.retrainSupBut} onClick={() => (navigate('/help'))}>
                                                View Support Page
                                            </div>
                                            <div className={styles.retrainCarTBut} onClick={() => setOpenAddEditNote(true)}>
                                                Inform Care Team
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className={styles.legendDiv}>
                                    {generateAidiLegends(aidiGraphPayloadV2?.chartData?.baselines ?? [])}
                                </div>
                                <AIDIGraphV2 data={aidiGraphPayloadV2?.chartData} />
                            </>
                        )
                    ) : null}
                </div>
            )}

            {openAddEditNote && <AddEditNotes
                patientInfo={patientDetailInfo}
                onClose={() => {
                    setOpenAddEditNote(false);
                }}
                onSave={(data) => {
                    onAddNote(data);
                    setOpenAddEditNote(false);
                }} 
                />}
        </div>
    );
}