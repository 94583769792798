import React, { useState, useEffect, useRef } from "react";
import styles from "./PatientAIDIPage.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { format, subDays, addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import AIDIGraphWrapperV2 from "./AIDIGraphWrapperV2";
import { getCurrentPatientSubjectID } from "../../../../Reducer/selectors/UtilSelector";
import { getAidiGraphPayloadV2 } from "../../../../Actions"
import UpdateBaselineModal from "./UpdateBaseLineModal";
export default function PatientAIDIPageV2() {
    const dispatch = useDispatch();
    const currentSubjectID = useSelector(getCurrentPatientSubjectID);
    const [isCalOpen, setIsCalOpen] = useState(false);
    const [dateRangeSelected, setDateRangeSelected] = useState([
        {
            startDate: subDays(new Date(), 60),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [actDateRangeSelected, setActDateRangeSelected] = useState([
        {
            startDate: subDays(new Date(), 60),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [showUpdateBaseLineModal, setShowUpdateBaseLineModal] = useState(false);
    const datePickerRef = useRef(null);
    const [calError, setCalError] = useState('');

    const handleCalClick = () => {
        setCalError('');
        setIsCalOpen((prev) => !prev);
    }

    const fetchChartData = async () => {

        const diffInMs = Math.abs(dateRangeSelected[0].startDate - dateRangeSelected[0].endDate); // Absolute value for positive difference
        const diffDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));

        console.log(diffDays);

        if (diffDays <= 120 && diffDays >= 30) {
            let selection = {
                startDate: new Date(dateRangeSelected[0].startDate),
                endDate: new Date(dateRangeSelected[0].endDate),
                key: "selection"
            }
            setDateRangeSelected([selection]);
            const payload = {
                subjectId: currentSubjectID,
                startDate: dateRangeSelected[0].startDate.toISOString().split("T")[0],
                endDate: dateRangeSelected[0].endDate.toISOString().split("T")[0],
            };
            dispatch(getAidiGraphPayloadV2(payload));
            setCalError('');
            setIsCalOpen(false);
            setActDateRangeSelected(dateRangeSelected);
        } else {
            setCalError('Please select a date range between 30 to 120 days.');
            setIsCalOpen(true);
            setDateRangeSelected(actDateRangeSelected);
        }
    };

    const handleCalChanges = (item) => {
        // console.log(item);
        // const diffInMs = Math.abs(item.selection.startDate - item.selection.endDate); // Absolute value for positive difference
        // const diffDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

        // if (diffDays <= 120 && diffDays >= 30) {
        //     setDateRangeSelected([item.selection]);
        // } else {
        //     let dupItem = item;
        //     const newStartDate = subDays(item.selection.endDate, 30);
        //     dupItem.selection.startDate = newStartDate;
        //     setDateRangeSelected([dupItem.selection]);
        // }
        setDateRangeSelected([item.selection]);
    }

    useEffect(() => {
        fetchChartData(); // Fetch chart data on initial load
    }, [currentSubjectID]);


    return (
        <div className={styles.rootDiv}>
            <div className={styles.containerDiv}>
                <div className={styles.datePickerDiv}>
                    <Tooltip title="Choose another date range">
                        <CalendarMonthIcon onClick={handleCalClick} />
                    </Tooltip>
                    <div className={styles.dateInputDiv}>
                        <input
                            value={`${format(dateRangeSelected[0].startDate, "MM/dd/yyyy")}  |  ${format(dateRangeSelected[0].endDate, "MM/dd/yyyy")}`}
                            readOnly
                            className={styles.dateInput}
                            onClick={handleCalClick}
                        />
                    </div>
                    <button
                        className={styles.updateButtonBaseLine}
                        onClick={() => setShowUpdateBaseLineModal(true)}
                    >
                        Update Baseline
                        <div className={styles.updateBaselineIcon}>
                            <GpsFixedIcon sx={{ padding: "0px !important", height: "20px !important", width: "20px !important", backgroundColor: "#AA346F !important", color: "#FFFFFF !important" }} />
                        </div>
                    </button>
                </div>
                {isCalOpen && (
                    <div className={styles.datePickerWrapper} ref={datePickerRef}>
                        <DateRangePicker
                            onChange={handleCalChanges}
                            editableDateInputs={false}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateRangeSelected}
                            direction="horizontal"
                            maxDate={addDays(new Date(), 1)}
                            rangeColors={["#aa346f"]}
                        />
                        <div className={styles.applyButtonDiv}>
                            <div className={styles.calErrorMsg}>
                                {calError}
                            </div>
                            <div
                                className={styles.cancelButton}
                                onClick={() => {
                                    setIsCalOpen(false);
                                    setDateRangeSelected(actDateRangeSelected);
                                }}
                            >
                                Cancel
                            </div>
                            <div
                                className={styles.applyButton}
                                onClick={() => {
                                    fetchChartData();
                                }}
                            >
                                Apply
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <AIDIGraphWrapperV2/>

            {showUpdateBaseLineModal && (
                <UpdateBaselineModal
                    open={showUpdateBaseLineModal}
                    onClose={() => setShowUpdateBaseLineModal(false)}
                    fetchChartData={fetchChartData}
                />
            )}
        </div>
    );
}
