import React from "react";
import styles from "./EcgGraph.module.css";
import { Line } from "react-chartjs-2";
import { FaHeartbeat } from "react-icons/fa";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default function EcgGraph(props) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const timeZoneFromDayjs = dayjs.tz.guess();

  const yAxisMin = -1;
  const yAxisMax = 1.5;
  const yAxisStep = 0.5;
  const yLabels = [];

  for (let i = yAxisMax; i >= yAxisMin; i -= yAxisStep) {
    yLabels.push(i.toFixed(1) + " mV");
  }

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    ctx.canvas.width = 5000;
    ctx.canvas.height = 250;

    return {
      labels: props.xValues,
      datasets: [
        {
          label: "ECG",
          fill: false,
          backgroundColor: "#e63946",
          borderColor: "#e63946",
          borderWidth: 2,
          pointRadius: 0,
          data: props.yValues,
        },
      ],
    };
  };

  const options = {
    responsive: false,
    tooltips: {
      backgroundColor: "rgba(255, 255, 255)",
      bodyFontColor: "rgba(53, 80, 112)",
      titleFontColor: "rgba(53, 80, 112)",
      callbacks: {
        label: function (tooltipItem) {
          return Number(tooltipItem.yLabel);
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            maxTicksLimit: 150,
            callback: function (value) {
              if (value === 0) {
                return null;
              } else {
                return value + " s";
              }
            },
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            tickMarkLength: 0,
          },
          ticks: {
            min: yAxisMin,
            max: yAxisMax,
            maxTicksLimit: 11,
            display: false, // Hide y-axis labels
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };

  return (
    <div className={styles.ecgCard}>
      <div className={styles.ecgCardTitle}>
        <div className={styles.ecgCardTitleIconStyle}>
          <FaHeartbeat
            className={styles.ecgCardIconStyles}
            style={{ color: `rgb(199, 31, 55,0.7)` }}
          />
        </div>
        <div
          className={styles.heartRateInformation}
          style={{ color: `rgb(199, 31, 55,0.7)` }}
        >
          Heart Rate {props.heartRate} bpm
        </div>
        <div className={styles.zoomEcgCard}>
          {dayjs
            .utc(props.ecgTimestamp)
            .tz(timeZoneFromDayjs)
            .format("YYYY-MM-DD HH:mm")}
        </div>
      </div>
      <div className={styles.ecgContainer}>
        <div className={styles.yAxisLabels}>
          {yLabels.map((label, index) => (
            <div key={index} className={styles.yLabel}>
              {label}
            </div>
          ))}
        </div>
        <div className={styles.chartArea}>
          <Line data={data} options={options}></Line>
        </div>
      </div>
    </div>
  );
}
