import PatientRegistrationHeader from "./PatientRegistrationHeader";
import styles from "./PatientRegistration.module.css";
import React, { useEffect, useRef, useState } from "react";
import { Tabs } from 'antd';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import Registration from "../PatientRegistration/Registration";
import GeneralInfoTab from "./RegistrationPages/GeneralInfoTab";
import PayerAndConditionTab from "./RegistrationPages/PayerAndConditionTab";
import FamilyInfoTab from "./RegistrationPages/FamilyInfoTab";
import CareTeamTab from "./RegistrationPages/CareTeamTab";
import FacilityInfoTab from "./RegistrationPages/FacilityInfoTab";
import { useDispatch, useSelector } from "react-redux";
import { getRegistrationStatus, getRegistrationStep } from "../../../Reducer/selectors/UtilSelector";
import { setRegistrationStep } from "../../../Actions/UtilActions";
import ReviewTab from "./RegistrationPages/ReviewTab";
import RegistrationHeader from "../PatientRegistration/RegistrationHeader";
import ReviewAllTab from "./RegistrationPages/ReviewAllTab";

export default function PatientRegistrationWrapper() {

    const dispatch = useDispatch();

    const { TabPane } = Tabs;

    const regStep = useSelector(getRegistrationStep);
    const [activeTab, setActiveTab] = useState("1");

    const regTabStatus = useSelector(getRegistrationStatus);

    const ref = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();
    const ref5 = useRef();

    const checkIfTabIsDone = (value) => {
        if (value === "1") {
            return regTabStatus.step1;
        } else if (value === "2") {
            return regTabStatus.step2;
        } else if (value === "3") {
            return regTabStatus.step3;
        } else if (value === "4") {
            return regTabStatus.step4;
        } else if (value === "5") {
            return regTabStatus.step5;
        } else if (value === "6") {
            return regTabStatus.review;
        }
        return false;
    };

    const tabChange = (key) => {
        // console.log("tabChange triggered");
        dispatch(setRegistrationStep(key));
        //setActiveTab(key);
    };

    const TabTitle = ({ name, value }) => {
        let dispStr = "Step " + value;
        return (<span className={styles.tabsLabel}>
            <div className={styles.tabNameDiv}>
                <div className={styles.tabNameDiv1}>{dispStr}</div>
                <div className={styles.tabNameDiv2}>{name}</div>
            </div>
            <div className={styles.tabsIconDiv}>{checkIfTabIsDone(value) ? <CheckCircleFilled style={{ color: '#4E253A' }} /> : <CheckCircleOutlined style={{ color: '#4E253A' }} />}</div>
        </span>)
    };

    const clearGeneralFields = () => {
        ref.current?.clearAllGeneralFields();
        ref2.current?.clearAllPayerFields();
        ref3.current?.clearAllFamilyFields();
        ref4.current?.clearAllCareTeamFields();
        ref5.current?.clearAllFacilityFields();
    };

    useEffect(() => {
        tabChange(regStep);
    }, []);
    return (
        <>
            <RegistrationHeader></RegistrationHeader>
            <div className={styles.headerDiv}>
                <Tabs defaultActiveKey={regStep} activeKey={regStep} onChange={tabChange}>
                    <TabPane
                        tab={<TabTitle name="General" value="1" />}
                        key="1"
                        disabled={!regTabStatus.step1 || regTabStatus.step5}
                    >
                        <GeneralInfoTab tabChange={tabChange} ref={ref} />
                    </TabPane>
                    <TabPane
                        tab={<TabTitle name="Payer & Programs" value="2" />}
                        key="2"
                        disabled={!regTabStatus.step2 || regTabStatus.step5}
                    >
                        <PayerAndConditionTab tabChange={tabChange} ref={ref2} />
                    </TabPane>
                    <TabPane
                        tab={<TabTitle name="Family Contact" value="3" />}
                        key="3"
                        disabled={!regTabStatus.step3 || regTabStatus.step5}
                    >
                        <FamilyInfoTab tabChange={tabChange} ref={ref3} />
                    </TabPane>
                    <TabPane
                        tab={<TabTitle name="Care Team" value="4" />}
                        key="4"
                        disabled={!regTabStatus.step4 || regTabStatus.step5}
                    >
                        <CareTeamTab tabChange={tabChange} ref={ref4} />
                    </TabPane>
                    <TabPane
                        tab={<TabTitle name="Facility & Admission" value="5" />}
                        key="5"
                        disabled={!regTabStatus.step5 || regTabStatus.step5}
                    >
                        <FacilityInfoTab tabChange={tabChange} ref={ref5} />
                    </TabPane>
                    <TabPane
                        tab={<TabTitle name="Review" value="6" />}
                        key="6"
                        disabled={!regTabStatus.review}
                    >
                        {/* <ReviewTab tabChange={tabChange} clearGeneralFields={clearGeneralFields} /> */}
                        <ReviewAllTab></ReviewAllTab>
                    </TabPane>
                </Tabs>
            </div>
        </>
    )
}