import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "./PatientDiagnosesTab.module.css";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DiagnosesTable from "./DiagnosesTable";
import Modal from "@material-ui/core/Modal";
import Backdrop from '@mui/material/Backdrop';
import Input from '@material-ui/core/Input';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from 'react-dropdown-select';
import Checkbox from '@mui/material/Checkbox';
import { Calendar } from "react-date-range";
import { addDays, format } from "date-fns";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedSubjectId, getUserProfile, getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { addNewDiagnosis, getAvailableDiagnosisRanks, getDiagnosesPayload } from "../../../../Actions";
import { getAvailableDiagnosisRankSelector, setAdmissionDateSelector } from "../../../../Reducer/selectors/PatientSelector";
import { setShowDiagnosesLoader } from "../../../../Actions/UtilActions";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';

const PatientDiagnosesTab = forwardRef((props, ref) => {

    const dispatch = useDispatch();

    const [isExpand, setIsExpand] = useState(true);
    const [showAddDx, setShowAddDx] = useState(false);

    const [searched, setSearched] = useState("");

    const [selectedCode, setSelectedCode] = useState("Search and select");
    const [selectedDesc, setSelectedDesc] = useState("Search and select");
    const [program, setProgram] = useState([]);
    const [rank, setRank] = useState([]);
    const [classification, setClassification] = useState([]);
    const [therapy, setTherapy] = useState(false);
    const [confidential, setConfidential] = useState(false);
    const [onset, setOnset] = useState([]);
    const [onsetDate, setOnsetDate] = useState(new Date());
    const [status, setStatus] = useState([]);
    const [resolvedDate, setResolvedDate] = useState(new Date());
    const [comment, setComment] = useState("");

    const [isCalOpen, setIsCalOpen] = useState(false);
    const [isCalOpen1, setIsCalOpen1] = useState(false);

    const subjectId = useSelector(getSelectedSubjectId);
    const admissionDate = useSelector(setAdmissionDateSelector);
    const userProfile = useSelector(getUserProfile);
    //console.log("userProfile: ", userProfile);

    const [isOnsetDateDisabled, setIsOnsetDateDisabled] = useState(true);
    const [isResolvedDateDisabled, setIsResolvedDateDisabled] = useState(true);

    const [isProgramFill, setIsProgramFill] = useState(true);
    const [isOnsetFill, setIsOnsetFill] = useState(true);
    const [isStatusFill, setIsStatusFill] = useState(true);
    const [isIcdFill, setIsIcdFill] = useState(true);

    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        if (onset && onset[0] && onset[0].value === "Choose Date") {
            setIsOnsetDateDisabled(false);
            setOnsetDate(new Date());
            setIsCalOpen(true);
        } else if (onset && onset[0] && onset[0].value === "Admission Date") {
            setOnsetDate(new Date(admissionDate));
            setIsOnsetDateDisabled(false);
            setIsCalOpen(false);
        } else if (onset && onset[0] && onset[0].value === "Unknown") {
            setOnsetDate(new Date());
            setIsOnsetDateDisabled(true);
            setIsCalOpen(false);
        }
    }, [onset]);

    useEffect(() => {
        console.log(status);
        if (status && status[0] && status[0].value === "Active") {
            setIsResolvedDateDisabled(true);
            setIsCalOpen1(false);
        } else if (status && status[0] && status[0].value === "Resolved") {
            setResolvedDate(new Date());
            setIsResolvedDateDisabled(false);
            setIsCalOpen1(true);
        } else if (status && status[0] && status[0].value === "Strikeout") {
            setResolvedDate(new Date());
            setIsResolvedDateDisabled(false);
            setIsCalOpen1(true);
        }
    }, [status]);

    const handleExpandClick = (event) => {
        event.preventDefault();
        setIsExpand(!isExpand);
    };

    const resetAllFields = () => {
        setSelectedCode("Search and select");
        setSelectedDesc("Search and select");
        setProgram([]);
        setRank([]);
        setClassification([]);
        setTherapy(false);
        setConfidential(false);
        setOnset([]);
        setOnsetDate(new Date());
        setStatus([]);
        setResolvedDate(new Date());
        setComment("");
        setIsOnsetDateDisabled(true);
        setIsResolvedDateDisabled(true);
        setErrMsg("");
        setIsProgramFill(true);
        setIsIcdFill(true);
        setIsOnsetFill(true);
        setIsStatusFill(true);
        setIsCalOpen(false);
        setIsCalOpen1(false);
    };

    const openAddDxDialog = (event) => {
        event.preventDefault();
        resetAllFields();
        let payload = {
            subjectId: subjectId.selectedSubjectId,
        };
        dispatch(getAvailableDiagnosisRanks(payload));
        setIsCalOpen1(false);
        setIsCalOpen(false);
        setShowAddDx(true);
    };

    const closeAddDxDialog = (event) => {
        event.preventDefault();
        setIsCalOpen1(false);
        setIsCalOpen(false);
        setShowAddDx(false);
    };

    const requestSearch = (event) => {
        event.preventDefault();
        setSearched(event.target.value);
        let searchVal = searched;
    };

    const clearSearch = (e) => {
        e.preventDefault();
        setSearched("");
    };

    const handleFocus = (event) => event.target.select();

    const [optionsProgram, setOptionsProgram] = useState([
        { value: 'RPM', label: 'RPM' },
        { value: 'CCM', label: 'CCM' },
        { value: 'Others', label: 'Others' },
        { value: 'None', label: 'None' },
    ]);

    // const [optionsRank, setOptionsRank] = useState([
    //     { value: 'Primary', label: 'Primary' },
    //     { value: 'Secondary', label: 'Secondary' },
    //     { value: 'Diagnosis #1', label: 'Diagnosis #1' },
    //     { value: 'Diagnosis #2', label: 'Diagnosis #2' },
    //     { value: 'Diagnosis #3', label: 'Diagnosis #3' },
    //     { value: 'Diagnosis #4', label: 'Diagnosis #4' },
    //     { value: 'Diagnosis #5', label: 'Diagnosis #5' },
    //     { value: 'Diagnosis #6', label: 'Diagnosis #6' },
    //     { value: 'Diagnosis #7', label: 'Diagnosis #7' },
    //     { value: 'Diagnosis #8', label: 'Diagnosis #8' },
    //     { value: 'Diagnosis #9', label: 'Diagnosis #9' },
    //     { value: 'Diagnosis #10', label: 'Diagnosis #10' },
    // ]);

    const optionsRank = useSelector(getAvailableDiagnosisRankSelector);

    const [optionsClassification, setOptionsClassification] = useState([
        { value: 'Admission', label: 'Admission' },
        { value: 'Discharge', label: 'Discharge' },
        { value: 'During stay', label: 'During stay' },
        { value: 'History', label: 'History' },
        { value: 'Admitting Dx', label: 'Admitting Dx' },
    ]);

    const [optionsOnset, setOptionsOnset] = useState([
        { value: 'Choose Date', label: 'Choose Date' },
        { value: 'Admission Date', label: 'Admission Date' },
        { value: 'Unknown', label: 'Unknown' },
    ]);

    const [optionsStatus, setOptionsStatus] = useState([
        { value: 'Active', label: 'Active' },
        { value: 'Resolved', label: 'Resolved' },
        { value: 'Strikeout', label: 'Strikeout' },
    ]);

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const handleTherapyCheckboxChange = (event) => {
        setTherapy(event.target.checked);
    };

    const handleConfidentialCheckboxChange = (event) => {
        setConfidential(event.target.checked);
    };

    const handleCalClick = (event) => {
        if (isCalOpen) {
            setIsCalOpen(false);
        } else if (!isOnsetDateDisabled && (onset[0] && onset[0].value !== 'Admission Date')) {
            setIsCalOpen(true);
        }
    };

    const handleCalClick1 = (event) => {
        if (isCalOpen1) {
            setIsCalOpen1(false);
        } else if (!isResolvedDateDisabled) {
            setIsCalOpen1(true);
        }
    };

    const handleCalClickCancel = (event) => {
        setIsCalOpen(false);
    }

    const handleCalClickCancel1 = (event) => {
        setIsCalOpen1(false);
    }

    const [options, setOptions] = useState([]);
    const previousController = useRef();

    const getData = (searchTerm) => {
        // if (previousController.current) {
        //   previousController.current.abort("Reason A");
        // }
        let newSearchTerm = searchTerm.replace(/\s/g, "&");
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        fetch(
            "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&maxList&terms=" +
            newSearchTerm,
            {
                signal,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                //console.log("search term: " + searchTerm + ", results: ", myJson[3]);
                const updatedOptions = myJson[3].map((p) => {
                    let tempTitle = p[0] + " - " + p[1];
                    return { title: tempTitle };
                });
                setOptions(updatedOptions);
            })
            .catch((ex) => {
                // This is how you can determine if the exception was due to abortion
                if (signal.aborted) {
                    // This is set by the promise which resolved first
                    // and caused the fetch to abort
                    const { reason } = signal;
                    // Use it to guide your logic...
                    console.log(`Fetch aborted with reason: ${reason}`);
                } else console.log(`Fetch failed with exception: ${ex}`);
            });
    };

    const onInputChange = (event, value, reason) => {
        if (value) {
            getData(value);
        } else {
            setOptions([]);
        }
    };

    const setICDSelection = (str) => {
        var icdInfo = str.split(/-(.*)/s);
        setSelectedCode(icdInfo[0]);
        setSelectedDesc(icdInfo[1]);
    };

    const checkOnsetConditions = () => {
        if (onset && onset[0] && onset[0].value !== "") {
            return true;
        } else {
            return false;
        }
    };

    const checkStatusConditions = () => {
        if (status && status[0] && status[0].value !== "") {
            console.log('status conditions are met!');
            return true;
        } else {
            console.log('status conditions are not met!');
            return false;
        }
    };

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const checkIfConditionsAreMet = async () => {
        if ((selectedCode !== "Search and select") && (selectedDesc !== "Search and select") && (program && program[0] && program[0].value !== "") && checkOnsetConditions() && checkStatusConditions() && (comment.length <= 300)) {
            console.log('conditions are met!');
            let payload = {
                desc: selectedDesc,
                onset: onset[0].value,
                onsetDate: onset[0].value !== 'Unknown' ? `${format(onsetDate, "yyyy-MM-dd")}` : null,
                icdCode: selectedCode,
                program: program[0].value.trim().toUpperCase(),
                rankDesc: rank ? rank[0] ? rank[0].value : '' : '',
                therapy: therapy,
                classification: classification ? classification[0] ? classification[0].value : '' : '',
                createdBy: userProfile.firstName + ' ' + userProfile.lastName,
                //createdDate: new Date(),
                confidential: confidential,
                status: status ? status[0] ? status[0].value.trim().toUpperCase() : '' : '',
                comment: comment,
                resolvedDate: status[0].value !== 'Active' ? `${format(resolvedDate, "yyyy-MM-dd")}` : null,
                subjId: subjectId.selectedSubjectId,
            }
            console.log(payload);
            dispatch(addNewDiagnosis(payload));
            dispatch(setShowDiagnosesLoader(true));
            setShowAddDx(false);
            await sleep(5000);
            dispatch(getDiagnosesPayload({ subjectId: subjectId.selectedSubjectId }));
        } else {
            console.log('conditions are not met!');
            setErrMsg("Please fill all the required fields marked *");
            if (!program[0] || program[0].value === '') {
                setIsProgramFill(false);
            }
            if (!onset[0] || onset[0].value === '') {
                setIsOnsetFill(false);
            }
            if (!status[0] || status[0].value === '') {
                setIsStatusFill(false);
            }
            if (selectedCode === "Search and select" || selectedDesc === "Search and select") {
                setIsIcdFill(false);
            }
        }
    };

    const handleCancelButton = (event) => {
        event.preventDefault();
        setShowAddDx(false);
    };

    const handleSaveButton = (event) => {
        event.preventDefault();
        checkIfConditionsAreMet();
    };

    const setOnsetDateHandler = (item) => {
        setOnsetDate(item);
        //setIsCalOpen(false);
    };

    const setResolvedDateHandler = (item) => {
        setResolvedDate(item);
        //setIsCalOpen1(false);
    };

    return (
        <div className={styles.mainDiv}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={showAddDx}
            ></Backdrop>
            <div className={styles.containerDiv}>
                <div className={styles.containerHeader}>
                    <div>

                    </div>
                    <div className={styles.headerText}>
                        Diagnosis
                    </div>
                    <div className={styles.expandButtonDiv} onClick={(event) => handleExpandClick(event)}>
                        {isExpand ? <KeyboardArrowUpIcon sx={{ color: "#4e253a" }} /> : <KeyboardArrowDownIcon sx={{ color: "#4e253a" }} />}
                    </div>
                </div>
                <div className={styles.containerBody}>
                    {isExpand ? <DiagnosesTable></DiagnosesTable> : <></>}
                </div>
            </div>
            <div className={styles.containerFooter}>
                <div className={styles.addDiagnosisDiv} onClick={e => openAddDxDialog(e)}>
                    + Add Diagnosis
                </div>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={showAddDx}
                onClose={e => closeAddDxDialog(e)}
                hideBackdrop={true}
            >
                <div className={styles.paper}>
                        <div className={styles.addDxHeader}>Add Diagnosis</div>
                        <div className={styles.addDxBody}>
                            <Autocomplete
                                options={options}
                                //value={searched}
                                onInputChange={onInputChange}
                                onChange={(e, value) => { value ? setICDSelection(value.title) : console.log('value null') }}
                                forcePopupIcon={false}
                                disableClearable={false}
                                getOptionLabel={(option) => option.title}
                                style={{ width: '90%', backgroundColor: '#FFFFFF', borderRadius: '30px', paddingLeft: '0px', paddingRight: '15px', paddingBottom: '10px', paddingTop: '10px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps, disableUnderline: true,
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    {<SearchIcon style={{ color: "#4E253A", marginTop: '5px', marginLeft: '5px', marginRight: '10px' }}></SearchIcon>}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                            />

                            <div className={styles.rowDiv}>
                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Code*</div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={selectedCode} onChange={(e) => setSelectedCode(e.currentTarget.value)} className={isIcdFill ? styles.inputFieldSelctedDisabled : styles.inputFieldSelctedDisabledReq} onFocus={(e) => handleFocus(e)} disabled />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Description*</div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={selectedDesc} onChange={(e) => setSelectedDesc(e.currentTarget.value)} className={isIcdFill ? styles.inputFieldSelctedDisabled : styles.inputFieldSelctedDisabledReq} onFocus={(e) => handleFocus(e)} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.rowDiv1}>
                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Program*</div>
                                    <div>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isProgramFill ? styles.dropdown : styles.dropdownReq}
                                            values={program}
                                            onChange={setProgram}
                                            options={optionsProgram}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Rank</div>
                                    <div>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdown}
                                            values={rank}
                                            onChange={setRank}
                                            options={optionsRank}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Classification</div>
                                    <div>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdown}
                                            values={classification}
                                            onChange={setClassification}
                                            options={optionsClassification}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv1}>
                                    <div className={styles.checkBoxDiv}><Checkbox value={therapy} onChange={handleTherapyCheckboxChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 24, color: '#A040AB' } }} /></div>
                                    <div className={styles.checkBoxLabel}>Therapy</div>
                                </div>

                                <div className={styles.fieldDiv1}>
                                    <div className={styles.checkBoxDiv}><Checkbox value={confidential} onChange={handleConfidentialCheckboxChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 24, color: '#A040AB' } }} /></div>
                                    <div className={styles.checkBoxLabel}>Confidential</div>
                                </div>
                            </div>

                            <div className={styles.rowDiv1}>
                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Onset*</div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isOnsetFill ? styles.dropdown : styles.dropdownReq}
                                            values={onset}
                                            onChange={setOnset}
                                            options={optionsOnset}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>
                                        Date (YYYY-MM-DD)
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input
                                            value={isOnsetDateDisabled ? `----/--/--` : `${format(onsetDate, "yyyy/MM/dd")}`}
                                            readOnly
                                            className={(onset[0] && onset[0].value === 'Admission Date') ? styles.inputFieldSelctedDOBDisabled : isOnsetDateDisabled ? styles.inputFieldSelctedDOBDisabled : styles.inputFieldSelctedDOB}
                                            onClick={(event) => handleCalClick(event)}
                                            disabled={isOnsetDateDisabled}
                                        />
                                        {isCalOpen && (
                                            <div className={styles.datePickerWrapper}>
                                                <Calendar
                                                    onChange={item => setOnsetDateHandler(item)}
                                                    editableDateInputs={false}
                                                    showSelectionPreview={true}
                                                    months={1}
                                                    date={onsetDate}
                                                    direction="horizontal"
                                                    maxDate={addDays(new Date(), 1)}
                                                    color={["#aa346f"]}
                                                />
                                                <div className={styles.applyButtonDiv}>
                                                    <div className={styles.cancelButton1} onClick={(event) => handleCalClickCancel(event)}> Cancel </div>
                                                    <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Confirm </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Status*</div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isStatusFill ? styles.dropdown : styles.dropdownReq}
                                            values={status}
                                            onChange={setStatus}
                                            options={optionsStatus}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>
                                        Date (YYYY-MM-DD)
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input
                                            value={isResolvedDateDisabled ? `----/--/--` : `${format(resolvedDate, "yyyy/MM/dd")}`}
                                            readOnly
                                            className={isResolvedDateDisabled ? styles.inputFieldSelctedDOBDisabled : styles.inputFieldSelctedDOB}
                                            onClick={(event) => handleCalClick1(event)}
                                        />
                                        {isCalOpen1 && (
                                            <div className={styles.datePickerWrapper}>
                                                <Calendar
                                                    onChange={item => setResolvedDateHandler(item)}
                                                    editableDateInputs={false}
                                                    showSelectionPreview={true}
                                                    months={1}
                                                    date={resolvedDate}
                                                    direction="horizontal"
                                                    maxDate={addDays(new Date(), 1)}
                                                    color={["#aa346f"]}
                                                />
                                                <div className={styles.applyButtonDiv}>
                                                    <div className={styles.cancelButton1} onClick={(event) => handleCalClickCancel1(event)}> Cancel </div>
                                                    <div className={styles.applyButton} onClick={(event) => handleCalClick1(event)}> Confirm </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.rowDiv2}>
                                <div className={styles.fieldLabel1}>
                                    Notes
                                </div>
                                <div className={styles.addNewNoteDiv}>
                                    <Input
                                        className={styles.notesEditor}
                                        type="text"
                                        placeholder="Start typing..."
                                        value={comment}
                                        multiline={true}
                                        onChange={(event) => setComment(event.target.value)}
                                        disableUnderline="true"
                                        minRows={5}
                                        maxRows={5}
                                    />  
                                </div>
                                {comment.length > 300 ? (<><div className={styles.notesCharCountRed}>
                                    {comment.length}/300
                                </div></>) : (<><div className={styles.notesCharCount}>
                                    {comment.length}/300
                                </div></>)}
                            </div>

                            <div className={styles.rowDiv3}>
                                <div className={styles.errMsgDiv}>
                                    {errMsg}
                                </div>
                                <div className={styles.buttonsDiv}>
                                    <div className={styles.cancelButton} onClick={e => handleCancelButton(e)}>
                                        Cancel
                                    </div>
                                    <div className={styles.saveButton} onClick={e => handleSaveButton(e)}>
                                        Save
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </Modal>
        </div>
    );
});

export default PatientDiagnosesTab;