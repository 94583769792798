
import styles from "./SearchBar.module.css";
import CloseIcon from '@mui/icons-material/Close';
import Input from '@material-ui/core/Input';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const SearchBar = ({ searched, onSearchChange, onSearchKeyUp, onResetSearch }) => {
    return (<Input
        className={styles.searchBar}
        type="text"
        placeholder="Search"
        value={searched}
        onChange={onSearchChange}
        onKeyUp={onSearchKeyUp}
        startAdornment={
            <InputAdornment position="start" className={styles.startAdornment}>
                <IconButton className={styles.searchIcon}>
                    <SearchIcon style={{ color: "#4E253A" }}></SearchIcon>
                </IconButton>
            </InputAdornment>
        }
        endAdornment={
            <InputAdornment position="end">
              {
                <IconButton className={styles.searchIcon}>
                  {searched === "" ? <></> : <CloseIcon style={{ color: "#4E253A" }} onClick={onResetSearch}></CloseIcon>}
                </IconButton>
              }
            </InputAdornment>
          }
        disableUnderline={true}
        style={{
            fontFamily: "GTWalsheimProRegular"
        }}
    />);
};

export default SearchBar;