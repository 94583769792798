import 'c3/c3.css';
import c3 from 'c3';
import 'c3/c3.min.css';
import { useEffect } from 'react';
import * as d3 from 'd3';
import { format, addDays } from 'date-fns';

export default function AIDIGraphV2({ data }) {
  useEffect(() => {
    //console.log("inside graph")
    //console.log(data)
    if (!data || !data.columns || data.columns.splines.length === 0) return;
    //console.log(data)
    const columns = [];
    const xsMapping = {};
    const colors = {};

    // Process splines
    data.columns.splines.forEach((spline) => {
      const xLabel = spline.x[0];
      const yLabel = xLabel.slice(0, -1) + 'y';
      spline.y[0] = yLabel;

      columns.push(spline.x, spline.y);
      xsMapping[spline.y[0]] = spline.x[0];
      colors[spline.y[0]] = spline.color;
    });

    data.columns.measurements.forEach((measurement) => {
      const xLabel = measurement.x[0];
      const yLabel = xLabel.slice(0, -1) + 'y';
      measurement.y[0] = yLabel;

      columns.push(measurement.x, measurement.y);
      xsMapping[measurement.y[0]] = measurement.x[0];
      colors[measurement.y[0]] = measurement.color;
    });

    // Generate regions with color
    const chartRegions = data?.baselines.map((region, index) => ({
      axis: 'x',
      start: region.start,
      end: region.end,
      class: `region-${index}`,
      color: region.color
    }));
    const allXValues = [];

    function parseJustMonthAndDate(str) {
      const month = str.getMonth();
      const date = str.getDate();

      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      return `${months[month]} ${date}`;
    };

    function parse12HTime(str) {
      const hours = str.getHours();
      const minutes = str.getMinutes() < 10 ? '0' + str.getMinutes() : str.getMinutes();

      const hours12 = hours === 12 ? 12 : hours % 12;
      const ampm = hours / 12 >= 1 ? 'PM' : 'AM'

      return `${hours12}:${minutes} ${ampm}`;
    };

    function fetchTickValues(minDate, maxDate) {
      console.log(minDate);
      let ticks = [minDate];
      let currDate = minDate;

      while (currDate < maxDate) {
        currDate = addDays(currDate, 5);
        ticks.push(currDate);
      }

      //ticks.push(new Date(maxDate));
      //console.log(ticks);
      return ticks;
    }

    // Initialize the chart
    var chart = c3.generate({
      bindto: '#chart',
      data: {
        xs: xsMapping,
        columns: columns,
        types: {
          ...Object.fromEntries(
            data.columns.splines.map((spline) => [spline.y[0], 'line'])
          ),
          ...Object.fromEntries(
            data.columns.measurements.map((measurement) => [measurement.y[0], 'scatter'])
          ),
        },
        colors: colors,
      },
      axis: {
        x: {
          type: 'timeseries',
          min: data?.minDate,
          max: data?.maxDate,
          tick: {
            format: '%e %b',
            rotate: 0,
            fit: false,
            centered: true,
            values: fetchTickValues(new Date(data?.minDate + 'T00:00:00-05:00'), new Date(data?.maxDate + 'T00:00:00-05:00'))
          },
        },
        y: {
          max: 1.0,
          min: 0.0
        }
      },
      regions: chartRegions,
      grid: {
        y: {
          lines: [],
        },
      },
      point: {
        show: true,
        r: 4,
        focus: {
          enabled: true,
          r: 6,
        },
      },
      size: {
        height: 400,
      },
      legend: {
        show: false,
      },
      tooltip: {
        grouped: false,
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          if (!d || d.length === 0) return '';

          const dataPoint = d[0];
          const date = format(dataPoint.x, 'MMM dd');
          const isSpline = data.columns.splines.some((spline) => spline.y[0] === dataPoint.id);
          let tooltipData = null;

          if (isSpline) {
            const spline = data.columns.splines.find((spline) => spline.y[0] === dataPoint.id);
            const index = spline.x.indexOf(format(dataPoint.x, 'yyyy-MM-dd')) - 1; // Adjust index for the first element label
            tooltipData = spline.tooltipData[index];
          } else {
            const measurement = data.columns.measurements.find((m) => m.y[0] === dataPoint.id);
            tooltipData = measurement ? measurement.tooltipData : null;
          }

          if (!tooltipData) return '';

          let html = `<div style="display: flex; align-items: center; border: none; padding: 5px 0; background-color: transparent; border-radius: 15px; font-size: 12px; gap: 0; flex-wrap: nowrap; position: relative;">`;

          html += `<div style="display: flex; align-items: center; padding: 10px 15px; background-color: #f8bbd0; border-radius: 20px; color: #b71c1c; min-width: 110px; height: 40px; margin-right: -5px; box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1); z-index: ${tooltipData.length + 1};">
              <div style="text-align: left; margin-right: 10px;">
                <div style="font-weight: bold; font-size: 14px; margin-bottom: 4px;">${parseJustMonthAndDate(new Date(tooltipData[7].value + ' UTC'))}</div>
                <hr style="border: none; border-top: 1px solid rgba(0, 0, 0, 0.1); width: 80%; margin: 0 auto;">
                <div style="font-weight: bold; font-size: 14px; margin-top: 2px;">${parse12HTime(new Date(tooltipData[7].value + ' UTC'))}</div>
              </div>
              <div style="width: 1px; height: 40px; background-color: rgba(0, 0, 0, 0.1); margin: 0 10px;"></div>
              <div style="text-align: center;">
                <div style="font-weight: bold; font-size: 14px; margin-bottom: 4px;">${dataPoint.value.toFixed(2)}</div>
                <hr style="border: none; border-top: 1px solid rgba(0, 0, 0, 0.1); width: 100%; margin: 0 auto;">
                <div style="font-size: 11px; color: #b71c1c;">AIDI</div>
              </div>
            </div>`;

          tooltipData.forEach(({ name, value }, index) => {
            if (name != "Time") {
              const isPlaceholder = parseInt(value) === 0 || isNaN(value);
              const backgroundColor = isPlaceholder ? '#e0e0e0' : '#fce4ec';
              const textColor = isPlaceholder ? '#757575' : '#ad1457';

              html += `<div style="padding: 10px 10px 10px 20px; background-color: ${backgroundColor}; border-radius: 20px; text-align: center; color: ${textColor}; min-width: 60px; height: 40px; margin-left: -20px; box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1); z-index: ${tooltipData.length - index};">
                <div style="font-weight: bold; font-size: 14px; margin-bottom: 4px;">${isPlaceholder ? '--' : value}</div>
                <hr style="border: none; border-top: 1px solid rgba(0, 0, 0, 0.1); width: 80%; margin: 0 auto;">
                <div style="font-size: 11px; color: ${textColor};">${name}</div>
              </div>`;
            }
          });

          html += `</div>`;
          return html;
        },
      },
      onrendered: function () {
        d3.selectAll('#chart .c3-axis-y .tick text').style('display', 'none');
        d3.select('#chart .c3-axis-y path').style('display', 'none');
        d3.selectAll('#chart .c3-axis-y .tick line').style('display', 'none');
        //d3.selectAll('#chart .c3-axis-x .tick line').style('display', 'none');
        d3.selectAll("#chart .c3-axis-x .mouse-line").style("opacity", "1");

        const svg = d3.select('#chart svg');
        chartRegions.forEach((region, index) => {
          svg.append('style').text(`
            .c3-region.region-${index} {
                fill: ${region.color};
                fill-opacity: 0.2;
            }
          `);
        });
      },
    });

    // Gradient creation for chart decoration
    const svg = chart.internal.svg;
    const defs = svg.append('defs');
    const gradient = defs.append('linearGradient')
      .attr('id', 'gradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
    const gradientColors = ['lightgreen', 'yellow', 'orange', 'red']
    gradientColors.forEach((color, index) => {
      gradient.append('stop')
        .attr('offset', `${(index / (gradientColors.length - 1)) * 100}%`)
        .attr('stop-color', color);
    });

    const marginLeft = chart.internal.margin.left;
    const marginTop = chart.internal.margin.top;
    const width = 9;
    const x = marginLeft - width - 30;
    const y = marginTop;

    svg.append('rect')
      .attr('x', x)
      .attr('y', y + 28)
      .attr('width', width)
      .attr('height', (chart.internal.height * 0.86))
      .attr('rx', width / 2)
      .attr('ry', width / 2)
      .style('fill', 'url(#gradient)');

    const ticks = [0, 0.3, 0.6, 1];
    ticks.forEach((tick) => {
      const tickY = y + (chart.internal.height * 0.84) * (1 - tick);
      svg.append('text')
        .attr('x', x + width + 10)
        .attr('y', tickY + 34)
        .text(tick)
        .style('font-size', '11px')
        .style('fill', 'black');
    });
  }, [data]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ transform: 'rotate(-90deg)', marginRight: '10px', fontSize: '14px', fontWeight: 'bold' }}>
        AIDI
      </div>
      <div id="chart" style={{ marginTop: '20px', flexGrow: 1 }} />
    </div>
  );
}
