import { aidiConstants } from "../Constants/AIDIConstants";
import subDays from "date-fns/subDays";

const initialState = {
    aidiHrPayload: [],
    aidiBrPayload: [],
    aidiBaselinePayload: [],
    aidiGraphPayload: {},
    newBaselineSD: "",
    newBaselineED: "",
    aidiBaselines: [],
    aidiRecordsTable: [],
    aidiBaselineAdded: false,
    aidiBaselineUpdated: false,
    isAddBaselineMode: false,
    isAddBaselineModeDone: false,
    selectedAIDITabId: 0,
    isThereNewBaselineRec: false,
    updateAidiBaselineStatusSuccess: false,
    isValidating: false,
    validationMessage: "",
    isAidiComputed: false,
    baselineLabels: [],
    baselineColors: [],
    aidiColors: [],
    aidiDateRange: [{
        startDate: subDays(new Date(), 120),
        endDate: new Date(),
        key: "selection",
    }],
};

export function aidiReducer(state = initialState, action) {
    switch (action.type) {
        case aidiConstants.GET_AIDI_HR_PAYLOAD:
            return {
                ...state,
            };
        case aidiConstants.GET_AIDI_HR_PAYLOAD_SUCCESS:
            return {
                ...state,
                aidiHrPayload: action.payload,
            };
        case aidiConstants.GET_AIDI_HR_PAYLOAD_FAILURE:
            return {
                ...state,
                aidiHrPayload: [],
            };
        case aidiConstants.GET_AIDI_BR_PAYLOAD:
            return {
                ...state,
            };
        case aidiConstants.GET_AIDI_BR_PAYLOAD_SUCCESS:
            return {
                ...state,
                aidiBrPayload: action.payload,
            };
        case aidiConstants.GET_AIDI_BR_PAYLOAD_FAILURE:
            return {
                ...state,
                aidiBrPayload: [],
            };
        case aidiConstants.GET_AIDI_BASELINE_PAYLOAD:
            return {
                ...state,
            };
        case aidiConstants.GET_AIDI_BASELINE_PAYLOAD_SUCCESS:
            return {
                ...state,
                aidiBaselinePayload: action.payload,
            };
        case aidiConstants.GET_AIDI_BASELINE_PAYLOAD_FAILURE:
            return {
                ...state,
            };
        case aidiConstants.SET_NEW_BASELINE_SD:
            return {
                ...state,
                newBaselineSD: action.payload,
            };
        case aidiConstants.SET_NEW_BASELINE_ED:
            return {
                ...state,
                newBaselineED: action.payload,
            };
        case aidiConstants.GET_ALL_AIDI_BASELINES:
            return {
                ...state,
            };
        case aidiConstants.GET_ALL_AIDI_BASELINES_SUCCESS:
            return {
                ...state,
                aidiBaselines: action.payload.aidiBaselines,
            };
        case aidiConstants.GET_ALL_AIDI_BASELINES_FAILURE:
            return {
                ...state,
                aidiBaselines: [],
            };
        case aidiConstants.POST_AIDI_BASELINE:
            return {
                ...state,
            };
        case aidiConstants.POST_AIDI_BASELINE_SUCCESS:
            return {
                ...state,
                aidiBaselineAdded: true,
            };
        case aidiConstants.POST_AIDI_BASELINE_FAILURE:
            return {
                ...state,
                aidiBaselineAdded: false,
            };
        case aidiConstants.UPDATE_AIDI_BASELINE:
            return {
                ...state,
            };
        case aidiConstants.UPDATE_AIDI_BASELINE_SUCCESS:
            return {
                ...state,
                aidiBaselineUpdated: true,
            };
        case aidiConstants.UPDATE_AIDI_BASELINE_FAILURE:
            return {
                ...state,
                aidiBaselineUpdated: false,
            };
        case aidiConstants.SET_IS_ADD_BASELINE_MODE:
            return {
                ...state,
                isAddBaselineMode: action.payload
            };
        case aidiConstants.SET_IS_ADD_BASELINE_MODE_DONE:
            return {
                ...state,
                isAddBaselineModeDone: action.payload
            };
        case aidiConstants.SET_SELECTED_AIDI_TAB_ID:
            return {
                ...state,
                selectedAIDITabId: action.payload
            };
        case aidiConstants.SET_IS_THERE_NEW_BASELINE_REC:
            return {
                ...state,
                isThereNewBaselineRec: action.payload
            };
        case aidiConstants.UPDATE_AIDI_BASELINE_STATUS:
            return {
                ...state,
            };
        case aidiConstants.UPDATE_AIDI_BASELINE_STATUS_SUCCESS:
            return {
                ...state,
                updateAidiBaselineStatusSuccess: true
            };
        case aidiConstants.UPDATE_AIDI_BASELINE_STATUS_FAILURE:
            return {
                ...state,
                updateAidiBaselineStatusSuccess: false
            };
        case aidiConstants.SET_IS_AIDI_VALIDATING:
            return {
                ...state,
                isValidating: action.payload
            };
        case aidiConstants.VALIDATE_AIDI_BASELINE:
            return {
                ...state,
            };
        case aidiConstants.VALIDATE_AIDI_BASELINE_SUCCESS:
            return {
                ...state,
                validationMessage: action.payload
            };
        case aidiConstants.VALIDATE_AIDI_BASELINE_FAILURE:
            return {
                ...state,
                validationMessage: action.payload
            };
        case aidiConstants.GET_AIDI_RECORD_TABLE:
            return {
                ...state,
            };
        case aidiConstants.GET_AIDI_RECORD_TABLE_SUCCESS:
            return {
                ...state,
                aidiRecordsTable: action.payload,
            };
        case aidiConstants.GET_AIDI_RECORD_TABLE_FAILURE:
            return {
                ...state,
                aidiRecordsTable: [],
            };
        case aidiConstants.GET_AIDI_GRAPH_PAYLOAD:
            return {
                ...state,
            };
        case aidiConstants.GET_AIDI_GRAPH_PAYLOAD_SUCCESS:
            return {
                ...state,
                aidiGraphPayload: action.payload,
            };
        case aidiConstants.GET_AIDI_GRAPH_PAYLOAD_FAILURE:
            return {
                ...state,
                aidiGraphPayload: [],
            };
        case aidiConstants.GET_AIDI_GRAPH_PAYLOAD_V2:
            return {
                ...state,
                loading: true
            };
        case aidiConstants.GET_AIDI_GRAPH_PAYLOAD_SUCCESS_V2:
            return {
                ...state,
                aidiGraphPayloadV2: action.payload,
                loading:false
            };
        case aidiConstants.GET_AIDI_GRAPH_PAYLOAD_FAILURE_V2:
            return {
                ...state,
                aidiGraphPayloadV2: null,
                loading:false
            };
        case aidiConstants.SET_IS_AIDI_COMPUTED:
            return {
                ...state,
                isAidiComputed: action.payload,
            };
        case aidiConstants.SET_BASELINE_LABELS:
            return {
                ...state,
                baselineLabels: action.payload,
            };
        case aidiConstants.SET_BASELINE_COLORS:
            return {
                ...state,
                baselineColors: action.payload,
            };
        case aidiConstants.SET_AIDI_COLORS:
            return {
                ...state,
                aidiColors: action.payload,
            };
        case aidiConstants.SET_AIDI_DATE_RANGE:
            return {
                ...state,
                aidiDateRange: action.payload,
            };
        default:
            return state;
    }
}
