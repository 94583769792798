import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Thresholds.module.css";
import {
  KeyboardArrowLeft as LeftIcon,
  KeyboardArrowRight as RightIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { CircularProgress } from "@mui/material";
import {
  getTempEdit,
  getSbpEdit,
  getDbpEdit,
  getPrEdit,
  getSpo2Edit,
  getHrEdit,
  getHrvEdit,
  getRrEdit,
  getFev1Edit,
  getPefEdit,
  getSugarEdit,
  getPainEdit,
  getWeightEdit,
  getAllEdit,
  getDisableAllEditButton,
  getBaselineSelector,
  getShowConfirmationBanner,
  getHasValidationError,
} from "../../../../Reducer/selectors/PatientSelector";
import {
  setTempEdit,
  setSbpEdit,
  setDbpEdit,
  setPrEdit,
  setSpo2Edit,
  setHrEdit,
  setHrvEdit,
  setRrEdit,
  setFev1Edit,
  setPefEdit,
  setSugarEdit,
  setPainEdit,
  setWeightEdit,
  setDisableAllEditButton,
  setNewThreshold,
  setShowConfirmationBanner,
  getLatestBaseline,
  setValidationError,
} from "../../../../Actions/PatientActions";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { getUserProfile, getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { globalConstants } from "../../../../Constants/GlobalConstants";
import { getCurrentPatientGUID, getCurrentPatientSubjectID, getPageLoader } from "../../../../Reducer/selectors/UtilSelector";
import { setShowThresholdsLoader } from "../../../../Actions/UtilActions";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    marginTop: "8px !important"
  },
}));

const ThresholdsParamsV2 = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  // ToDo ACF-948: Switch to local state from Redux
  const isAllEditable = useSelector(getAllEdit);
  const isDisableAllEditButton = useSelector(getDisableAllEditButton);
  const showConfirmationBanner = useSelector(getShowConfirmationBanner);
  const subjectThresholdsData = useSelector(getBaselineSelector);
  const userRole = useSelector(getUserRole);
  const currentPatientGUID = useSelector(getCurrentPatientGUID);
  const curPatientSubjectId = useSelector(getCurrentPatientSubjectID);
  const userProfile = useSelector(getUserProfile);
  const loader = useSelector(getPageLoader);
  const disableAllEdits = (userRole === globalConstants.USER_ROLE_CARE_MANAGER);
  const [validationErrors, setValidationErrors] = useState({});
  const hasGlobalErrors = useSelector(getHasValidationError);

  const editableSelectors = {
    temp: useSelector(getTempEdit),
    sbp: useSelector(getSbpEdit),
    dbp: useSelector(getDbpEdit),
    pulseRate: useSelector(getPrEdit),
    spo2: useSelector(getSpo2Edit),
    heartRate: useSelector(getHrEdit),
    hrv: useSelector(getHrvEdit),
    breathingRate: useSelector(getRrEdit),
    fev1: useSelector(getFev1Edit),
    pef: useSelector(getPefEdit),
    sugar: useSelector(getSugarEdit),
    pain: useSelector(getPainEdit),
    weight: useSelector(getWeightEdit),
  };

  const thresholdsConfig = [
    { key: "temp", label: "Temperature (F)", setEdit: setTempEdit, min: 94, max: 109, decimalAllowed: true },
    { key: "sbp", label: "Systolic BP (mmHg)", setEdit: setSbpEdit, min: 50, max: 250, requiredIf: "dbp" },
    { key: "dbp", label: "Diastolic BP (mmHg)", setEdit: setDbpEdit, min: 30, max: 150, requiredIf: "sbp" },
    { key: "pulseRate", label: "Pulse Rate (bpm)", setEdit: setPrEdit, min: 30, max: 250 },
    { key: "spo2", label: "SpO2 (%)", setEdit: setSpo2Edit, min: 60, max: 100 },
    { key: "heartRate", label: "Heart Rate (bpm)", setEdit: setHrEdit, min: 30, max: 250 },
    { key: "hrv", label: "Heart Rate Variability (ms)", setEdit: setHrvEdit, min: 1, max: 200 },
    { key: "breathingRate", label: "Resp. Rate (br/min)", setEdit: setRrEdit, min: 6, max: 50 },
    { key: "fev1", label: "FEV1 (L)", setEdit: setFev1Edit, min: 0.2, max: 12, decimalAllowed: true },
    { key: "pef", label: "PEF (L/sec)", setEdit: setPefEdit, min: 0.5, max: 15, decimalAllowed: true },
    { key: "sugar", label: "Blood Sugar (mg/dL)", setEdit: setSugarEdit, min: 20, max: 500 },
    { key: "pain", label: "Pain Level (0-10)", setEdit: setPainEdit, min: 0, max: 10 },
    { key: "weight", label: "Weight (Lbs)", setEdit: setWeightEdit, min: 20, max: 1000, decimalAllowed: true },
  ];  

  const [thresholds, setThresholds] = useState({});
  const [iconStates, setIconStates] = useState({});

  const initializeStates = () => {
    const initialThresholds = {};
    const initialIcons = {};
    thresholdsConfig.forEach(({ key }) => {
        
      initialThresholds[key] = {
        low: subjectThresholdsData[`${key}Low`] ?? "",
        value: subjectThresholdsData[`${key}Value`] ?? "",
        high: subjectThresholdsData[`${key}High`] ?? "",
      };
      initialIcons[key] = true;
    });
    setThresholds(initialThresholds);
    setIconStates(initialIcons);
  };

  useEffect(() => {
    initializeStates();
    setTrendStates();
  }, []);

  useEffect(() => {
    // Update the state
    const hasErrors = Object.values(validationErrors).some((error) => error);
    dispatch(setValidationError(hasErrors));
  }, [validationErrors]);

  useEffect(() => {
    if (!hasGlobalErrors)
        clearErrors();
  }, [hasGlobalErrors]);

  const handleToggleEdit = (key, setEdit) => {
    dispatch(setEdit(!editableSelectors[key]));
    dispatch(setDisableAllEditButton(!isDisableAllEditButton));
    setIconStates((prev) => ({ ...prev, [key]: !prev[key] }));
    setTrendStates();
    clearErrorByKey(key);
  };

  const clearErrors = () => {
    // Update state if the input is valid
    setValidationErrors({});
  };

  const clearErrorByKey = (key) => {
    // Update state if the input is valid
    setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [key]: null, // Clear any previous errors for this key
    }));
  };

  const handleInputChange = (key, type, value) => {
    const updatedThreshold = { ...thresholds[key], [type]: value };
    const { low, high } = updatedThreshold;
    const validationResult = validateThreshold(key, parseFloat(low), parseFloat(high));
    setThresholds((prev) => ({
        ...prev,
        [key]: updatedThreshold,
      }));
    if (validationResult.valid) {
        clearErrorByKey(key);
    } else {
      // Show error without updating the value
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [key]: validationResult.message, // Update the error message
      }));
    }
  };
  

  const validateThreshold = (key, low, high) => {
    const config = thresholdsConfig.find((item) => item.key === key);
    if (!config) return { valid: true, message: "" };
  
    const { min, max, label, decimalAllowed, requiredIf } = config;

    console.log(min, max, label, decimalAllowed, requiredIf);
  
    if (low < 0 || high < 0) {
        return { valid: false, message: `${label}: Negative values are not allowed.` };
    }

    if (!decimalAllowed && ( (low && !Number.isInteger(low)) || (high && !Number.isInteger(high)))) {
        return { valid: false, message: `${label}: Decimal values are not allowed.` };
    }

    if (low < min || low > max) {
      return { valid: false, message: `${label}: Low value must be between ${min} and ${max}.` };
    }
  
    if (high < min || high > max) {
      return { valid: false, message: `${label}: High value must be between ${min} and ${max}.` };
    }
  
    if (low >= high) {
      return { valid: false, message: `${label}: Low value must be less than High value.` };
    }
  
    return { valid: true, message: "" };
  };

  const handleFocus = (event) => event.target.select();

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const submitNewThresholdFinal = async () => {
    // Dynamically create the payload using the `thresholds` state
    const payload = Object.keys(thresholds).reduce((acc, key) => {
        const { low, value, high } = thresholds[key];
        return {
        ...acc,
        [`${key}Low`]: low,
        [`${key}Value`]: value,
        [`${key}High`]: high,
        };
    }, {
        subjectId: curPatientSubjectId,
        userId: userProfile.id,
        creationDate: new Date(),
    });

    // Dispatch the payload and show the loader
    dispatch(setNewThreshold(payload));
    dispatch(setShowThresholdsLoader(true));

    await sleep(2000);

    // Fetch the latest baseline data
    dispatch(getLatestBaseline({ subjectGuid: currentPatientGUID }));
    };

    useImperativeHandle(ref, () => {
        return {
          submitNewThresholdFinal: submitNewThresholdFinal,
          setAllIconClicked: setAllIconClicked,
          setTrendStates: setTrendStates,
          setNewTrendsWPaylaod: setNewTrendsWPayload,
        };
      });

      const setAllIconClicked = (val) => {
        setIconStates((prev) => {
          const updatedIcons = {};
          Object.keys(prev).forEach((key) => {
            updatedIcons[key] = val;
          });
          return updatedIcons;
        });
      };

      const setNewTrendsWPayload = (payload) => {
        setThresholds((prev) => {
          const updatedThresholds = { ...prev };
            
          // Iterate through the payload keys to dynamically update thresholds
          Object.keys(payload).forEach((key) => {
            const match = key.match(/^(.*?)(High|Low|Value)$/);
            if (match) {
              const [_, metric, type] = match;
              // Update the corresponding thresholds state
              if (updatedThresholds[metric]) {
                updatedThresholds[metric] = {
                  ...updatedThresholds[metric],
                  [type.toLowerCase()]: payload[key],
                };
              }
            }
          });
      
          return updatedThresholds;
        });
      };
      
      const setTrendStates = () => {
        setThresholds((prev) => {
          const updatedThresholds = { ...prev };
      
          thresholdsConfig.forEach(({ key }) => {
            updatedThresholds[key] = {
              low: subjectThresholdsData[`${key}Low`] ?? "",
              value: subjectThresholdsData[`${key}Value`] ?? "",
              high: subjectThresholdsData[`${key}High`] ?? "",
            };
          });
      
          return updatedThresholds;
        });
      };
      
      
  const renderIconDiv = (key, isDisabled) => {
    if (disableAllEdits) return null;
    const hasError = !!validationErrors[key];

    const children = showConfirmationBanner ? (<></>) : (
            
        iconStates[key] ? (
          
            <HtmlTooltip
          title={
            <React.Fragment>
              <div className={styles.tootlTipHeading}>Edit</div>
            </React.Fragment>
          }
        >
          <div className={styles.editButton} 
          onClick={() => {handleToggleEdit(key, thresholdsConfig.find((item) => item.key === key).setEdit);
              } }
          >
              
              <EditIcon style={{ height: "15px", width: "15px" }} />
              </div></HtmlTooltip>
        ) : (
          
          <div className={styles.editOptions}>
               <HtmlTooltip
            title={
              <React.Fragment>
                <div className={styles.tootlTipHeading}>
                  {hasError ? "Resolve errors" : "Confirm"}
                </div>
              </React.Fragment>
            }
          >
            <CheckIcon style={{ 
                                  height: "15px", 
                                  width: "15px",
                                  cursor: hasError ? "not-allowed" : "pointer",
                                  opacity: hasError ? 0.5 : 1, 
                              }} 
                              onClick={() => {
                                  if (!hasError) {
                                    dispatch(setShowConfirmationBanner(true));
                                  }
                                }} /></HtmlTooltip>
                                <HtmlTooltip
            title={
              <React.Fragment>
                <div className={styles.tootlTipHeading}>Cancel</div>
              </React.Fragment>
            }>
            <ClearIcon style={{ height: "15px", width: "15px" }} onClick={() => {handleToggleEdit(key, thresholdsConfig.find((item) => item.key === key).setEdit);
          }} /></HtmlTooltip>
          </div>
        )
    );
    
    return children ? (
          <div className={isDisabled ? styles.iconEditDisabled : styles.iconEdit}>
            {children}
          </div>
      ) : null;
  };

  const renderRow = ({ key, label, setEdit }) => {
    const isEditable = editableSelectors[key];
    const threshold = thresholds[key] || {};
    const dependencies = Object.keys(editableSelectors).filter((depKey) => depKey !== key);
    const isDisabled = dependencies.some((depKey) => !editableSelectors[depKey]);
    return (
      <tr className={isAllEditable && isDisabled ? styles.rowWrapperDisabled : styles.rowWrapper} key={key}>
        <td className={styles.param}>{label}</td>
        <td className={styles.paramValue}>
        
          <div>
            <input
              type="number"
              value={threshold.low}
              onChange={(e) => handleInputChange(key, "low", e.target.value)}
              className={isEditable ? styles.inputLowValue : styles.inputLowValueFocused}
              disabled={isEditable}
              onFocus={(e) => handleFocus(e)}
            />
          </div>
          <div className={styles.lowSign}>
            <LeftIcon />
          </div>
        </td>
        <td className={styles.baselineWrapper}>
            <HtmlTooltip title={validationErrors[key] || ""} open={!!validationErrors[key]}>
          <input
            type="number"
            value={threshold.value}
            onChange={(e) => handleInputChange(key, "value", e.target.value)}
            className={isEditable ? styles.inputValue : styles.inputValueFocused}
            disabled={isEditable}
            onFocus={(e) => handleFocus(e)}
          />
          </HtmlTooltip>
        </td>
        <td className={styles.paramValue}>
          <div className={styles.highSign}>
            <RightIcon />
          </div>
          <div>
            <input
              type="number"
              value={threshold.high}
              onChange={(e) => handleInputChange(key, "high", e.target.value)}
              className={isEditable ? styles.inputHighValue : styles.inputHighValueFocused}
              disabled={isEditable}
              onFocus={(e) => handleFocus(e)}
            />
          </div>
        </td>
        <td className={styles.iconTd}>
            {renderIconDiv(key, isDisabled)}
        </td>
      </tr>
    );
  };

  return (
    <div>
      {subjectThresholdsData ? (
        <table className={styles.valuesTable}>
          <tbody>
            {thresholdsConfig.map(renderRow)}
          </tbody>
        </table>
      ) : (
        <div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFFFFF`, height: `100%` }}>
          <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}/>
        </div>
      )}
    </div>
  );
});

export default ThresholdsParamsV2;
