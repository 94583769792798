export function getDisplayLabelWithEhrId(firstName, lastName, ehrId, subjectId) {
    const fullName = firstName + " " + lastName;
    var mName = fullName.trim().split(' ').filter(Boolean);
    let finalName = "";

    if (mName.length > 3) {
        finalName = mName[0].substring(0, 1) + '.' + mName[1].substring(0, 1) + '.' + mName[2].substring(0, 1) + '. ' + mName.slice(3);
    } else if (mName.length > 2) {
        finalName = mName[0].substring(0, 1) + '.' + mName[1].substring(0, 1) + '. ' + mName.slice(2);
    } else if (mName.length > 1 && mName[0]) {
        finalName = mName[0].substring(0, 1) + '. ' + mName.slice(1);
    }
    else finalName = mName[0];

    return (finalName && ehrId ? (
        `${finalName} (${ehrId})`
    ) : (
        subjectId ? `ADR-${subjectId}` : ""
    ));
};

function isEmpty(value) {
    return (value == null || value.trim().length === 0);
};

export function getDisplayLabelWithoutEhrId(firstName, lastName, subjectId) {
    const fullName = firstName + " " + lastName;
    var mName = fullName.trim().split(' ').filter(Boolean);
    let finalName = "";

    if (mName.length > 3) {
        finalName = mName[0].substring(0, 1) + '.' + mName[1].substring(0, 1) + '.' + mName[2].substring(0, 1) + '. ' + mName.slice(3);
    } else if (mName.length > 2) {
        finalName = mName[0].substring(0, 1) + '.' + mName[1].substring(0, 1) + '. ' + mName.slice(2);
    } else if (mName.length > 1 && mName[0]) {
        finalName = mName[0].substring(0, 1) + '. ' + mName.slice(1);
    }
    else finalName = mName[0];

    return (isEmpty(fullName) ? `ADR - ${subjectId}` : finalName);

};

export function getRegPatientFinalName(prefix, firstName, lastName, suffix) {
    return (prefix + ' ' + firstName.substring(0, 1) + '. ' + lastName);
};

export const generateParameterString = (parameters, selectedParameters) => {
    return parameters
        .map((param) => {
            if (!param.enabled) {
                return "0";
            } else if (selectedParameters.includes(param.name)) {
                return "1";
            } else {
                return "2";
            }
        })
        .join("");
};




export function prepTrendsPayload(rawPayload) {
    let daily_avg = rawPayload.daily_avg;
    let daily_avg_x = rawPayload.daily_avg_x;
    let measurement_1 = rawPayload.measurement_1;
    let measurement_1_x = rawPayload.measurement_1_x;
    let measurement_2 = rawPayload.measurement_2;
    let measurement_2_x = rawPayload.measurement_2_x;
    let measurement_3 = rawPayload.measurement_3;
    let measurement_3_x = rawPayload.measurement_3_x;
    let measurement_4 = rawPayload.measurement_4;
    let measurement_4_x = rawPayload.measurement_4_x;
    let measurement_5 = rawPayload.measurement_5;
    let measurement_5_x = rawPayload.measurement_5_x;
    let measurement_6 = rawPayload.measurement_6;
    let measurement_6_x = rawPayload.measurement_6_x;
    let measurement_7 = rawPayload.measurement_7;
    let measurement_7_x = rawPayload.measurement_7_x;
    let measurement_8 = rawPayload.measurement_8
    let measurement_8_x = rawPayload.measurement_8_x;

    daily_avg.unshift("daily_avg");
    daily_avg_x.unshift("daily_avg_x");
    measurement_1.unshift("measurement_1");
    measurement_1_x.unshift("measurement_1_x");
    measurement_2.unshift("measurement_2");
    measurement_2_x.unshift("measurement_2_x");
    measurement_3.unshift("measurement_3");
    measurement_3_x.unshift("measurement_3_x");
    measurement_4.unshift("measurement_4");
    measurement_4_x.unshift("measurement_4_x");
    measurement_5.unshift("measurement_5");
    measurement_5_x.unshift("measurement_5_x");
    measurement_6.unshift("measurement_6");
    measurement_6_x.unshift("measurement_6_x");
    measurement_7.unshift("measurement_7");
    measurement_7_x.unshift("measurement_7_x");
    measurement_8.unshift("measurement_8");
    measurement_8_x.unshift("measurement_8_x");


    let finalPayload = [daily_avg, daily_avg_x,
        measurement_1, measurement_1_x,
        measurement_2, measurement_2_x,
        measurement_3, measurement_3_x,
        measurement_4, measurement_4_x,
        measurement_5, measurement_5_x,
        measurement_6, measurement_6_x,
        measurement_7, measurement_7_x,
        measurement_8, measurement_8_x,
    ];

    return finalPayload;
};