import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
const HtmlTooltip = styled(({ className, customStyles = {}, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme, customStyles }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      marginTop: "8px !important",
      ...customStyles,
    },
  }));

  export default HtmlTooltip;