import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  modalOverlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    position: "relative",
    width: "800px",
    maxHeight: "95vh",
    maxWidth: "90%",
    backgroundColor: "#f8e1e7",
    borderRadius: "16px",
    outline: "none",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    fontFamily: "GTWalsheimProRegular !important",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& *": {
      fontFamily: "GTWalsheimProRegular !important",
    }
  },
  loaderOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    zIndex: 1000,
  },

  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#AA346F",
    alignContent: "center",
    justifyContent: "center",
    padding: "8px"


  },
  modalTitle: {
    fontSize: "18px",
    color: "white",
    fontWeight: "700",

    margin: "0 auto"
  },
  closeButton: {
    backgroundColor: "#F4E2E7",
    padding: "3px",
    marginRight: "10px",
    '&:hover': {
      backgroundColor: 'white',
      color: 'inherit',
    },
  },
  subtitle: {
    color: "#AA346F",
    fontSize: "18px",
    fontWeight: "700",
  },
  instructions: {
    color: "#AA346F",
    marginBottom: "10px",
    fontSize: "16px",
    fontWeight: "300",
    borderBottom: "0.58px solid #F14C004D",
    paddingBottom: "15px"
  },
  calendarTile: {
    position: "relative",
    zIndex: 0,
  },
  calendarContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& abbr": {
      textDecoration: "none",
      textTransform: "none",
    },
    "& .react-calendar__month-view__weekdays__weekday": {
      color: "#7C596A"
    },
    "& .react-calendar": {
      border: "none",
      backgroundColor: "#f8e1e7",
      fontFamily: "GTWalsheimProRegular",
    },
    "& .react-calendar__navigation": {
      display: "flex",
      justifyContent: "center",
      marginBottom: "10px",
      alignItems: "center"
    },
    "& .react-calendar__navigation button": {
      color: "#aa346f",
      fontSize: "15px",
      fontWeight: "bold",
      backgroundColor: "white",
      margin: "10px",
      borderRadius: "50%",
      height: "30px",
      boxShadow: "0px 0px 4.68px 0px #00000040",
      width: "30px",
      minWidth: "30px !important"
    },
    calendarTile: {
      position: "relative",
      zIndex: 0,
    },
    "& .react-calendar__navigation__arrow": {
      fontSize: "20px !important"
    },
    "& .react-calendar__navigation__label": {
      border: "1.11px solid #AA346F",
      boxShadow: "0px 2.752px 2.752px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px !important",
      height: "36px!important"
    },
    "& .react-calendar__viewContainer": {
      // flexDirection: "column",
      alignItems: "center"
    },
    "& .react-calendar__month-view": {
      position: "relative",
      "&:before": {
        content: 'attr(data-heading)',
        display: "block",
        textAlign: "center",
        fontSize: "15px",
        fontWeight: "bold",
        color: "#4E253A",
        marginBottom: "10px",
        paddingBottom: "10px",
        borderBottom: "0.75px solid #F14C004D"
      },
    },
    "& .react-calendar__month-view__weekdays": {
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#aa346f",
    },
    "& .react-calendar__tile": {
      position: "relative", // Add this line
      maxWidth: "initial !important",
      height: "30px",
      width: "30px",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      backgroundColor: "#fff",
      color: "#AA346F",
      //   border: "1px solid #fff",
      cursor: "pointer",
      fontWeight: "normal",
    },
    "& .react-calendar__tile:disabled": {
      backgroundColor: "#ddd",
      color: "black",
      cursor: "default",
      fontWeight: "normal",
    },
    "& .react-calendar__tile--now": {
      backgroundColor: "#f0c7d1",
    },
    "& .react-calendar__tile--active": {
      backgroundColor: "#aa346f !important",
      color: "#fff !important",
    },
    "& .react-calendar__tile:hover:not(:disabled)": {
      backgroundColor: "#f0c7d1",
    },
    "& .react-calendar__month-view__days__day--neighboringMonth": {
      visibility: "hidden",
    },
    "& .react-calendar__month-view__days": {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
      display: "grid !important",
      justifyItems: "center",
      rowGap: "5px"
    }
  },
  selectedDate: {
    backgroundColor: "#4E253A",
    color: "white",
    borderRadius: "50%",
  },
  highlightedDate: {
    backgroundColor: "#aa346f  !important",
    color: "white !important",
  },
  interactiveButtons: {
    display: "flex",
    justifyContent: "left",
    padding: "5px 0px 5px 0px",
    "& button": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 10px",
      height: "22px",
      backgroundColor: "#f8e1e7",
      color: "white",
      border: "1px solid #aa346f",
      cursor: "pointer",
      fontWeight: "normal",
      borderRadius: "20px",
      border: "0px",
      minWidth: "80px"
    },
  },
  parameterSelection: {
    margin: "20px 0px 20px 0px",

  },
  parameterButtons: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#FFFAFD",
    padding: "6px",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",

    "& button": {
      margin: "5px",
      backgroundColor: "#fff",
      color: "#AA346F",
      borderRadius: "40px",
      padding: "2px 20px",
      cursor: "pointer",
      fontWeight: "bold",
      border: "0.73px solid #AA346F",
      fontSize: "12px",
    },
    "& button.selected": {
      backgroundColor: "#AA346F",
      color: "#fff",
      borderColor: "transparent"
    },
    "& button:disabled": {
      backgroundColor: "#E6E6E6",
      color: "grey",
      borderColor: "transparent"
    },
  },
  paramSubtitle: {
    color: "#4E253A",
    borderRadius: "4px 4px 0px 0px",
    fontSize: "14px",
    fontWeight: "700",
    backgroundColor: "#FFD2E9",
    padding: "5px",
    display: "flex",
    alignItems: "center"

  },
  notesInput: {
    marginBottom: "20px",
  },
  requiredField: {
    color: "red",
    fontSize: "14px",
    marginTop: "5px",
  },
  modalActions: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  cancelButton: {
    backgroundColor: "#F4E2E7",
    color: "#aa346f",
    border: "1px solid #aa346f",
    borderRadius: "20px",
    padding: "5px 10px",
    cursor: "pointer",
    fontWeight: "bold",
    minWidth: "100px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    }
  },
  applyButton: {
    backgroundColor: "#aa346f",
    color: "#fff",
    border: "none",
    borderRadius: "20px",
    padding: "5px 10px",
    cursor: "pointer",
    fontWeight: "bold",
    marginLeft: "20px",
    minWidth: "100px",

    "&:hover": {
      backgroundColor: "#4E253A",
    }
  },
  textFieldNoBorder: {
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      padding: "6px",
      fontSize: "13px !important",
    }
  },
  notesTextCount: {
    display: "flex",
    flexDirection: "row",
    flexDirection: "row-reverse",
    marginTop: "5px",
    marginRight: "2px"
  },
  counter: {
    fontFamily: "GTWalsheimProRegular",
    fontSize: "11px",
    color: "#aa346f"
  }

}));

export default useStyles;
