export const aidiConstants = {

    GET_AIDI_HR_PAYLOAD: 'GET_AIDI_HR_PAYLOAD',
    GET_AIDI_HR_PAYLOAD_SUCCESS: 'GET_AIDI_HR_PAYLOAD_SUCCESS',
    GET_AIDI_HR_PAYLOAD_FAILURE: 'GET_AIDI_HR_PAYLOAD_FAILURE',
    GET_AIDI_BR_PAYLOAD: 'GET_AIDI_BR_PAYLOAD',
    GET_AIDI_BR_PAYLOAD_SUCCESS: 'GET_AIDI_BR_PAYLOAD_SUCCESS',
    GET_AIDI_BR_PAYLOAD_FAILURE: 'GET_AIDI_BR_PAYLOAD_FAILURE',
    SET_NEW_BASELINE_SD: 'SET_NEW_BASELINE_SD',
    SET_NEW_BASELINE_ED: 'SET_NEW_BASELINE_ED',
    SET_IS_ADD_BASELINE_MODE: 'SET_IS_ADD_BASELINE_MODE',
    SET_IS_ADD_BASELINE_MODE_DONE: 'SET_IS_ADD_BASELINE_MODE_DONE',
    SET_SELECTED_AIDI_TAB_ID: 'SET_SELECTED_AIDI_TAB_ID',
    GET_AIDI_BASELINE_PAYLOAD: 'GET_AIDI_BASELINE_PAYLOAD',
    GET_AIDI_BASELINE_PAYLOAD_SUCCESS: 'GET_AIDI_BASELINE_PAYLOAD_SUCCESS',
    GET_AIDI_BASELINE_PAYLOAD_FAILURE: 'GET_AIDI_BASELINE_PAYLOAD_FAILURE',

    //GET ALL AIDI BASELINES
    GET_ALL_AIDI_BASELINES: "GET_ALL_AIDI_BASELINES",
    GET_ALL_AIDI_BASELINES_SUCCESS: "GET_ALL_AIDI_BASELINES_SUCCESS",
    GET_ALL_AIDI_BASELINES_FAILURE: "GET_ALL_AIDI_BASELINES_FAILURE",

    //POST AIDI BASELINE
    POST_AIDI_BASELINE: "POST_AIDI_BASELINE",
    POST_AIDI_BASELINE_SUCCESS: "POST_AIDI_BASELINE_SUCCESS",
    POST_AIDI_BASELINE_FAILURE: "POST_AIDI_BASELINE_FAILURE",

    //UPDATE AIDI BASELINE
    UPDATE_AIDI_BASELINE: "UPDATE_AIDI_BASELINE",
    UPDATE_AIDI_BASELINE_SUCCESS: "UPDATE_AIDI_BASELINE_SUCCESS",
    UPDATE_AIDI_BASELINE_FAILURE: "UPDATE_AIDI_BASELINE_FAILURE",

    SET_IS_THERE_NEW_BASELINE_REC: 'SET_IS_THERE_NEW_BASELINE_REC',

    UPDATE_AIDI_BASELINE_STATUS: 'UPDATE_AIDI_BASELINE_STATUS',
    UPDATE_AIDI_BASELINE_STATUS_SUCCESS: 'UPDATE_AIDI_BASELINE_STATUS_SUCCESS',
    UPDATE_AIDI_BASELINE_STATUS_FAILURE: 'UPDATE_AIDI_BASELINE_STATUS_FAILURE',

    SET_IS_AIDI_VALIDATING: 'SET_IS_AIDI_VALIDATING',

    VALIDATE_AIDI_BASELINE: 'VALIDATE_AIDI_BASELINE',
    VALIDATE_AIDI_BASELINE_SUCCESS: 'VALIDATE_AIDI_BASELINE_SUCCESS',
    VALIDATE_AIDI_BASELINE_FAILURE: 'VALIDATE_AIDI_BASELINE_FAILURE',

    SET_VALIDATION_MESSAGE: 'SET_VALIDATION_MESSAGE',

    GET_AIDI_RECORD_TABLE: 'GET_AIDI_RECORD_TABLE',
    GET_AIDI_RECORD_TABLE_SUCCESS: 'GET_AIDI_RECORD_TABLE_SUCCESS',
    GET_AIDI_RECORD_TABLE_FAILURE: 'GET_AIDI_RECORD_TABLE_FAILURE',

    GET_AIDI_GRAPH_PAYLOAD: 'GET_AIDI_GRAPH_PAYLOAD',
    GET_AIDI_GRAPH_PAYLOAD_SUCCESS: 'GET_AIDI_GRAPH_PAYLOAD_SUCCESS',
    GET_AIDI_GRAPH_PAYLOAD_FAILURE: 'GET_AIDI_GRAPH_PAYLOAD_FAILURE',

    GET_AIDI_GRAPH_PAYLOAD_V2: 'GET_AIDI_GRAPH_PAYLOAD_V2',
    GET_AIDI_GRAPH_PAYLOAD_SUCCESS_V2: 'GET_AIDI_GRAPH_PAYLOAD_SUCCESS_V2',
    GET_AIDI_GRAPH_PAYLOAD_FAILURE_V2: 'GET_AIDI_GRAPH_PAYLOAD_FAILURE_V2',

    SET_IS_AIDI_COMPUTED: 'SET_IS_AIDI_COMPUTED',
    SET_BASELINE_LABELS: 'SET_BASELINE_LABELS',
    SET_BASELINE_COLORS: 'SET_BASELINE_COLORS',
    SET_AIDI_COLORS: 'SET_AIDI_COLORS',

    SET_AIDI_DATE_RANGE: 'SET_AIDI_DATE_RANGE',
};
