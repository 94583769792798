import { call, put, takeLatest, select } from "redux-saga/effects";
// import axios from "axios";
import Transport from "../Transport/AidarTransport";
// import {setPa } from "../Actions/UtilActions";
import domainCF from "../Components/API/domainCF";
import * as notesActions from '../Actions';
import { setShowLoader, setShowPatientNotesLoader } from "../Actions/UtilActions";
import { selectedRecordIdForNotes, setOpenAddNotesPanel, setSelectedTimestamp, setShowNotesDrawer, setShowNotesPanel } from "../Actions";
import { selectedRecordIdForNotesSelector } from "../Reducer/selectors/NotesSelector";
import { useSelector } from "react-redux";
import { getUserRole } from "../Reducer/selectors/DashboardSelector";
import { globalConstants } from "../Constants/GlobalConstants";

function* getPatientNotesSaga(action) {
  let userRole = action.payload.userRole;
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  let GET_PATIENT_NOTES = '';
  const { subjectId, recordId, startDate, endDate } = action.payload;


  if (userRole === globalConstants.USER_ROLE_AIDAR_ADMIN || userRole === globalConstants.USER_ROLE_BILLING_PHYSICIAN) {
    GET_PATIENT_NOTES = `${domainCF}/api/getSubjectVitalsNotesBySubjectIdAndRecordId?subjectId=${subjectId}&recordId=${recordId}`;
    if (startDate) {
      GET_PATIENT_NOTES += `&startDate=${startDate}`;
    }
    if (endDate) {
      GET_PATIENT_NOTES += `&endDate=${endDate}`;
    }
  } else {
    GET_PATIENT_NOTES = `${domainCF}/api/getGeneralNotesBySubjectIdAndRecordId?subjectId=${action.payload.subjectId}&recordId=${action.payload.recordId}`;
  };

  try {
    // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
    const response = yield Transport.genericOperation(GET_PATIENT_NOTES, headers, {}, 'GET');

    if (!response) {
      yield put(notesActions.getPatientNotesFailure());
    } else {
      yield put(notesActions.getPatientNotesSuccess(response));
    }
    yield put(setShowLoader(false));
    yield put(setShowPatientNotesLoader(false));
  } catch (e) {
    yield put(setShowLoader(false));
    yield put(setShowPatientNotesLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* postPatientNotesSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const POST_PATIENT_NOTES = `${domainCF}/api/addSubjectVitalsNote`;
  console.log("POST NOTES PAYLOAD: ", action.payload);

  try {
    // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
    const response = yield Transport.genericOperation(POST_PATIENT_NOTES, headers, action.payload, "POST");
    console.log("POST NOTES RESPONSE: ", response);

    if (!response) {
      yield put(notesActions.postPatientNotesFailure());
    } else {
      yield put(notesActions.postPatientNotesSuccess(response));
      const req = {
        subjectId: action.payload.subjectId,
        recordId: '',
        userRole: action.payload.userRole,
        startDate: action.payload.startDate || null,
        endDate: action.payload.endDate || null
      }
      yield put(notesActions.getPatientNotes(req));
    }
    yield put(setShowPatientNotesLoader(false));
  } catch (e) {
    yield put(setShowPatientNotesLoader(false));
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* updatePatientNoteSaga(action) {
  var token = sessionStorage.getItem("IdToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const UPDATE_PATIENT_NOTE = `${domainCF}/api/updateSubjectVitalsNote`;

  let new_payload = {
    id: action.payload.id,
    subjectId: action.payload.subjectId,
    recordId: action.payload.recordId,
    recordTimestamp: action.payload.recordTimestamp,
    createdDateTime: action.payload.createdDateTime,
    createdBy: action.payload.createdBy,
    note: action.payload.note,
    isBookmarked: action.payload.isBookmarked,
    tags: action.payload.tags,
    timezoneStr: action.payload.timezoneStr,
  };

  try {
    const response = yield Transport.genericOperation(UPDATE_PATIENT_NOTE, headers, new_payload, "POST");

    if (!response) {
      yield put(notesActions.updatePatientNoteFailure());
    } else {
      const recordId = yield select(selectedRecordIdForNotesSelector);
      yield put(notesActions.updatePatientNoteSuccess(response));
      const req = {
        subjectId: action.payload.subjectId,
        recordId: recordId,
        userRole: action.payload.userRole,
        startDate: action.payload.startDate || null,
        endDate: action.payload.endDate || null
      }
      yield put(notesActions.getPatientNotes(req));
    }
  } catch (e) {
    console.error("getChildInfoSaga Saga ERROR ", e);
    throw e;
  }
}

function* addNotesToRecordFromTableSaga(action) {
  yield put(selectedRecordIdForNotes(action.payload.recordId));
  yield put(setShowNotesPanel(true));
  yield put(setShowNotesDrawer(true));
  yield put(setOpenAddNotesPanel(true));
  yield put(setSelectedTimestamp(action.payload.timeStamp));
}

export default function* notesSaga() {

  try {
    yield takeLatest(notesActions.getPatientNotes, getPatientNotesSaga);
    yield takeLatest(notesActions.postPatientNotes, postPatientNotesSaga);
    yield takeLatest(notesActions.updatePatientNote, updatePatientNoteSaga);
    yield takeLatest(notesActions.addNotesToRecordFromTable, addNotesToRecordFromTableSaga);
  } catch {
    yield;
  }
}