import React from "react";
import styles from "./LfGraph.module.css";
import { Line } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import PEFIcon from "../../../../../../../Assets/icons/PEF-icon.svg";

export default function LfGraph(props) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const timeZoneFromDayjs = dayjs.tz.guess();

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    ctx.canvas.width = 1200;
    ctx.canvas.height = 300;
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#F0A3EB");
    gradient.addColorStop(0.2, "#F6A8E8");
    gradient.addColorStop(1, "#FFFFFF");

    const updatedXValues = [...props.xValues, 10];
    const updatedYValues = [...props.yValues, 0.001];

    const dataPoints = updatedXValues.map((x, index) => ({ x: x, y: updatedYValues[index] }));

    return {
      datasets: [
        {
          label: "Exhalation Curve",
          fill: "start",
          backgroundColor: gradient,
          borderColor: "#AA346F",
          borderWidth: 2,
          pointRadius: 0,
          pointBackgroundColor: "#AA346F",
          data: dataPoints,
        },
      ],
    };
  };

  const options = {
    responsive: false,
    scales: {
      xAxes: [
        {
          type: "linear",
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            display: true,
            maxTicksLimit: 11,
            min: 0,
            max: 10,
            stepSize: 1,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            maxTicksLimit: 11,
            callback: function (value) {
              return value + " L/s";
            },
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };

  return (
    <div className={styles.graphContent}>
      <Grid container>
        <Grid xs={12} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.lfGraphCard}>
            <div className={styles.headerDiv}>
              <div className={styles.lungRateInformation}>
                <img src={PEFIcon} style={{ marginRight: "7px" }} />
                PEF {props.pef} L/sec (Peak Expiratory Flow)
              </div>
              <div className={styles.timestampLungFunction}>
                {dayjs.utc(props.timestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
              </div>
            </div>

            <div className={styles.lfGraphContent}>
              <Line data={data} options={options} className={styles.graphStyles}></Line>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
