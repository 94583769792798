import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./PatientAIDIPage.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { compareAsc, format } from "date-fns";
import Button from "@mui/material/Button";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { makeStyles } from "@material-ui/core/styles";
import subDays from "date-fns/subDays";
import { addDays } from "date-fns";
import RenderBRHRGraph from "./Graphs/RenderBRHRGraph";
import { ReactComponent as MouthlabIcon } from "../../../../Assets/icons/Vitals.svg";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton } from "@material-ui/core";
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch, useSelector } from "react-redux";
import { getAidiBaselinePayload, getAidiBaselinePayloadSuccess, getAidiBrPayload, getAidiGraphPayload, getAidiHrPayload, getAidiRecordByRangeTable, setAidiDateRange, setBaselineColors, setBaselineLabels, setIsAddBaselineMode, setIsAddBaselineModeDone, setIsAidiValidating, setIsThereNewBaselineRec, setSelectedAIDITab, updateAidiBaselineStatus, validateAidiBaseline } from "../../../../Actions";
import { getAidiDateRangeSelector, getAidiValidationMessage, getBaselineColorsSelector, getBaselineLabelsSelector, getIsAddBaselineMode, getIsAddBaselineModeDone, getIsAidiComputedSelector, getIsAidiValidating, getIsThereNewBaselineRec, getNewBaselineED, getNewBaselineSD, getSelectedPatientBaselinePayload } from "../../../../Reducer/selectors/AIDISelector";
import CancelIcon from '@mui/icons-material/Cancel';
import Backdrop from '@mui/material/Backdrop';
import { getAidiValidationLoader, getCurrentPatientSubjectID, getPatientAidiLoader } from "../../../../Reducer/selectors/UtilSelector";
import { setShowAidiValidationLoader, setShowPatientAidiLoader } from "../../../../Actions/UtilActions";
import { CircularProgress } from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import AIDIGraphWrapper from "./AIDIGraphWrapper";
import TimelineIcon from '@mui/icons-material/Timeline';
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";


export default function PatientAIDIPage() {

    const dispatch = useDispatch();
    const isAddBaselineMode = useSelector(getIsAddBaselineMode);
    const isAddBaselineModeDone = useSelector(getIsAddBaselineModeDone);
    const currentSubjectID = useSelector(getCurrentPatientSubjectID);

    const isThereANewBaselineRec = useSelector(getIsThereNewBaselineRec);

    const baselinePayload = useSelector(getSelectedPatientBaselinePayload);

    const isValidating = useSelector(getIsAidiValidating);
    const aidiValidationLoader = useSelector(getAidiValidationLoader);

    const currUserId = useSelector(getUserProfile).id;
    const selectedBaselineSD = useSelector(getNewBaselineSD);
    const selectedBaselineED = useSelector(getNewBaselineED);

    const baselines = useSelector(getSelectedPatientBaselinePayload);

    const validationMessage = useSelector(getAidiValidationMessage);

    const baselineColors = useSelector(getBaselineColorsSelector);

    const baselineLabels = useSelector(getBaselineLabelsSelector);

    const [showConfirmBaselinePopUp, setShowConfirmBaselinePopUp] = useState(false);

    const ref = useRef(null);

    const useStyles = makeStyles(() => ({
        tooltip: {
            marginTop: 5,
            height: "8px !important",
            width: "8px !important",
            background: "black",
            fontFamily: "GTWalsheimProRegular",
        },
    }));
    const classes = useStyles();

    const refOne = useRef(null);

    // const payload = {
    //     subjectId: currentSubjectID,
    //     startDate: subDays(new Date(), 30),
    //     endDate: new Date(),
    // };

    // dispatch(getAidiHrPayload(payload));

    // Hide dropdown on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setIsCalOpen(false);
        }
    };

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            setIsCalOpen(false);
        }
    };

    const [isCalOpen, setIsCalOpen] = useState(false);
    const [dateRangeApplied, setDateRangeApplied] = useState([
        {
            startDate: subDays(new Date(), 120),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [dateRangeSelected, setDateRangeSelected] = useState(dateRangeApplied);
    //const dateRange = useSelector(getAidiDateRangeSelector);

    const handleCalClick = (event) => {
        event.preventDefault();
        if (isCalOpen) {
            setDateRangeSelected(dateRangeApplied);
            setIsCalOpen(false);
        } else {
            setDateRangeApplied(dateRangeSelected);
            setIsCalOpen(true);
        }
    };

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const fetchAIDIRecords = async () => {

        const tenDaysBeforeEndDate = subDays(dateRangeSelected[0].endDate, 10);
        const compVal = compareAsc(tenDaysBeforeEndDate, dateRangeSelected[0].startDate);

        if (compVal === -1) {
            dateRangeSelected[0].startDate = tenDaysBeforeEndDate;
        };

        setDateRangeApplied(dateRangeSelected);
        setIsCalOpen(false);
        const payload = {
            subjectId: currentSubjectID,
            startDate: (dateRangeSelected[0].startDate).toISOString().split('T')[0],
            endDate: (dateRangeSelected[0].endDate).toISOString().split('T')[0],
        };

        dispatch(setShowPatientAidiLoader(true));
        await sleep(3000);
        dispatch(getAidiHrPayload(payload));
        dispatch(getAidiBrPayload(payload));
        dispatch(getAidiBaselinePayload(payload));
        dispatch(getAidiGraphPayload(payload));
        dispatch(getAidiRecordByRangeTable(payload));
        dispatch(setIsAddBaselineModeDone(false));
        dispatch(setIsAidiValidating(false));
    };

    const handleCalChanges = (item) => {
        //console.log("selected date item: ", item);
        setDateRangeSelected([item.selection]);
        // dispatch(setAidiDateRange([item.selection]));
    };

    const acceptTheRecBaseline = () => {
        setShowConfirmBaselinePopUp(false);
        const payload = {
            subjectId: currentSubjectID,
            userId: currUserId,
            accepted: true
        };
        dispatch(updateAidiBaselineStatus(payload));
        fetchAIDIRecords();
        dispatch(setIsAddBaselineMode(false));
    }

    const rejectTheRecBaseline = () => {
        const payload = {
            subjectId: currentSubjectID,
            userId: currUserId,
            accepted: false
        };
        dispatch(updateAidiBaselineStatus(payload));
        dispatch(setIsThereNewBaselineRec(false));
        fetchAIDIRecords();
        dispatch(setIsAddBaselineMode(true));
    };

    const startAddNewBaselineMode = () => {
        dispatch(setIsAddBaselineModeDone(false));
        dispatch(setIsAidiValidating(false));
        dispatch(setIsAddBaselineMode(true));
    };

    useEffect(() => {
        if (isAddBaselineMode) {
            ref.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, [isAddBaselineMode, isAddBaselineModeDone]);

    useEffect(() => {

        const payload = {
            subjectId: currentSubjectID,
            startDate: (dateRangeSelected[0].startDate).toISOString().split('T')[0],
            endDate: (dateRangeSelected[0].endDate).toISOString().split('T')[0],
        };

        dispatch(setShowPatientAidiLoader(true));
        dispatch(getAidiHrPayload(payload));
        dispatch(getAidiBrPayload(payload));
        dispatch(getAidiBaselinePayload(payload));
        dispatch(getAidiGraphPayload(payload));
        dispatch(getAidiRecordByRangeTable(payload));
    }, [currentSubjectID]);

    const loader = useSelector(getPatientAidiLoader);

    if (loader) {
        return (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>);
    };

    const validateTheBaseline = async () => {
        dispatch(setIsAidiValidating(true));
        dispatch(setShowAidiValidationLoader(true));
        dispatch(setIsAddBaselineModeDone(false));
        setShowConfirmBaselinePopUp(false);
        await sleep(3000);
        const payload = {
            subjectId: currentSubjectID,
            userId: currUserId,
            startDate: selectedBaselineSD,
            endDate: selectedBaselineED,
            baselines: baselinePayload,
            baselineColors: baselineColors,
            baselineLabels: baselineLabels,
        };
        dispatch(validateAidiBaseline(payload));
        // fetchAIDIRecords();
    };

    const clearBaselineSelection = () => {
        baselines.pop();
        baselineLabels.pop();
        baselineColors.pop();
        dispatch(getAidiBaselinePayloadSuccess(baselines));
        dispatch(setBaselineColors(baselineColors));
        dispatch(setBaselineLabels(baselineLabels));
        dispatch(setIsAddBaselineModeDone(false));
        dispatch(setIsAddBaselineMode(true));
    };


    const generateBaselineLegends = () => {

        const baselineColorsLen = baselineColors.length;
        const baselineLabelsLen = baselineLabels.length;

        if (!isThereANewBaselineRec) {
            return (
                <>
                    {(baselineColorsLen > 0 && baselineLabelsLen > 0) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[0] }}>{"B" + baselineLabels[0].slice(9)}</div>{baselineLabels[0]}
                    </div>)}

                    {(baselineColorsLen > 1 && baselineLabelsLen > 1) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[1] }}>{"B" + baselineLabels[1].slice(9)}</div>{baselineLabels[1]}
                    </div>)}

                    {(baselineColorsLen > 2 && baselineLabelsLen > 2) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[2] }}>{"B" + baselineLabels[2].slice(9)}</div>{baselineLabels[2]}
                    </div>)}

                    {(baselineColorsLen > 3 && baselineLabelsLen > 3) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[3] }}>{"B" + baselineLabels[3].slice(9)}</div>{baselineLabels[3]}
                    </div>)}

                    {(baselineColorsLen > 4 && baselineLabelsLen > 4) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[4] }}>{"B" + baselineLabels[4].slice(9)}</div>{baselineLabels[4]}
                    </div>)}

                    {(baselineColorsLen > 5 && baselineLabelsLen > 5) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[5] }}>{"B" + baselineLabels[5].slice(9)}</div>{baselineLabels[5]}
                    </div>)}
                </>
            )
        } else {
            return (
                <>
                    {(baselineColorsLen > 1 && baselineLabelsLen > 1) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[0] }}>{"B" + baselineLabels[0].slice(9)}</div>{baselineLabels[0]}
                    </div>)}

                    {(baselineColorsLen > 2 && baselineLabelsLen > 2) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[1] }}>{"B" + baselineLabels[1].slice(9)}</div>{baselineLabels[1]}
                    </div>)}

                    {(baselineColorsLen > 3 && baselineLabelsLen > 3) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[2] }}>{"B" + baselineLabels[2].slice(9)}</div>{baselineLabels[2]}
                    </div>)}

                    {(baselineColorsLen > 4 && baselineLabelsLen > 4) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[3] }}>{"B" + baselineLabels[3].slice(9)}</div>{baselineLabels[3]}
                    </div>)}

                    {(baselineColorsLen > 5 && baselineLabelsLen > 5) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[4] }}>{"B" + baselineLabels[4].slice(9)}</div>{baselineLabels[4]}
                    </div>)}

                    {(baselineColorsLen > 6 && baselineLabelsLen > 6) && (<div className={styles.baselineLegendDiv}>
                        <div className={styles.baselineIcon} style={{ backgroundColor: baselineColors[5] }}>{"B" + baselineLabels[5].slice(9)}</div>{baselineLabels[5]}
                    </div>)}

                    {(<div className={styles.baselineLegendDivRec}>
                        <div className={styles.baselineIconRec}>BR</div>Recommended Baseline
                    </div>)}
                </>
            )
        }

    };

    // const HtmlTooltip = styled(({ className, ...props }) => (
    //     <Tooltip {...props} classes={{ popper: className }} />
    // ))(({ theme }) => ({
    //     [`& .${tooltipClasses.tooltip}`]: {
    //         backgroundColor: '#f5f5f9',
    //         color: 'rgba(0, 0, 0, 0.87)',
    //         maxWidth: 220,
    //         fontSize: theme.typography.pxToRem(12),
    //         border: '1px solid #dadde9',
    //     },
    // }));

    return (
        <div className={styles.rootDiv}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={showConfirmBaselinePopUp}
            ></Backdrop>
            <div className={styles.containerDiv}>
                <div className={styles.datePickerDiv}>
                    <Tooltip title="Choose another date range" classes={{ tooltip: classes.tooltip }}>
                        <CalendarMonthIcon onClick={(event) => handleCalClick(event)}></CalendarMonthIcon>
                    </Tooltip>
                    <div className={styles.dateInputDiv}>
                        <input
                            value={`${format(dateRangeSelected[0].startDate, "MM/dd/yyyy")}  |  ${format(dateRangeSelected[0].endDate, "MM/dd/yyyy")}`}
                            readOnly
                            className={styles.dateInput}
                            onClick={(event) => handleCalClick(event)}
                        />
                    </div>
                    {/* <div>
                        <Button startIcon={<TableChartOutlinedIcon />} onClick={() => fetchAIDIRecords()} className={styles.exportButton}>
                            View
                        </Button>
                    </div> */}
                </div>
                {isCalOpen && (
                    <div className={styles.datePickerWrapper} ref={refOne}>
                        <DateRangePicker
                            onChange={(item) => handleCalChanges(item)}
                            editableDateInputs={false}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateRangeSelected}
                            direction="horizontal"
                            maxDate={addDays(new Date(), 1)}
                            rangeColors={["#aa346f"]}
                        />
                        <div className={styles.applyButtonDiv}>
                            <div className={styles.cancelButton} onClick={(event) => handleCalClick(event)}> Cancel </div>
                            <div className={styles.applyButton} onClick={() => fetchAIDIRecords()}> Apply </div>
                        </div>
                    </div>
                )}
            </div>

            <AIDIGraphWrapper></AIDIGraphWrapper>


            <div className={styles.brhrGraphsWrapper}>
                <div className={styles.containerHeader}>
                    <div className={styles.headingDiv}>
                        <div className={styles.mlIconWrapper}>
                            <TimelineIcon color="#4E253A" style={{ height: '18px', width: '18px', fill: "#4E253A" }}></TimelineIcon>
                        </div>  MouthLab Vitals
                    </div>
                    <div className={styles.addBaselineButton}>
                        <div className={styles.addNewBaselineDiv}>
                            {!(isThereANewBaselineRec || isAddBaselineModeDone) && (isAddBaselineMode ?
                                (<>
                                    <div className={styles.applyimage} >
                                        <div className={styles.labelDiv}>
                                            Select New Baseline
                                        </div></div>
                                    <Tooltip title="Cancel" classes={{ tooltip: classes.tooltip }}>
                                        <IconButton style={{ padding: "8px" }} >
                                            <CancelIcon onClick={() => dispatch(setIsAddBaselineMode(false))} color="#ffffff" style={{ height: '25px', width: '25px', color: "#ffffff", marginRight: "5px", marginLeft: "5px" }}></CancelIcon>
                                        </IconButton>
                                    </Tooltip>
                                </>)
                                : (<Tooltip title="Add New Baseline" classes={{ tooltip: classes.tooltip }}>
                                    <IconButton style={{ padding: "8px" }} >
                                        <AddCircleIcon onClick={() => startAddNewBaselineMode()} color="#ffffff" style={{ height: '25px', width: '25px', color: "#ffffff" }}></AddCircleIcon>
                                    </IconButton>
                                </Tooltip>)
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.legendDiv}>
                    <div className={styles.brLegendDiv}>
                        <CircleIcon style={{ height: '15px', width: '15px', color: "#F14C00", marginRight: "5px", marginLeft: "5px" }}></CircleIcon> Avg Breathing Rate (BRPM)
                    </div>
                    <div className={styles.hrLegendDiv}>
                        <CircleIcon style={{ height: '15px', width: '15px', color: "#694FC0", marginRight: "5px", marginLeft: "5px" }}></CircleIcon> Avg Heart Rate (BPM)
                    </div>
                    {generateBaselineLegends()}
                </div>
                <RenderBRHRGraph></RenderBRHRGraph>
            </div>
            {(isThereANewBaselineRec && !isAddBaselineMode) && (<div className={styles.promptDiv}>
                <div className={styles.newBaselinePrompt}>
                    <div className={styles.promptMessage}>
                        Do you accept the recommended baseline?
                        <div className={styles.baselineRecIcon}>BR</div>
                    </div>
                    <div className={styles.noButton} onClick={() => rejectTheRecBaseline()}>
                        No <CloseIcon style={{ height: "20px", width: "20px" }}></CloseIcon>
                    </div>
                    <div className={styles.yesButton} onClick={() => setShowConfirmBaselinePopUp(true)}>
                        Yes <CheckIcon style={{ height: "20px", width: "20px" }}></CheckIcon>
                    </div>
                </div>
            </div>)}

            {/* {(isThereATempBaseline && !isAddBaselineMode) && (<div className={styles.promptDiv}>
                <div className={styles.newBaselinePrompt1}>
                    <div className={styles.promptMessage}>
                        Do you want to confirm this as the new Baseline {baselinePayload.length}
                        <div className={styles.baselineRecIcon}>B{baselinePayload.length}</div>?
                    </div>
                    <div className={styles.yesButton} onClick={() => acceptTheRecBaseline()}>
                        Yes <CheckIcon style={{ height: "20px", width: "20px" }}></CheckIcon>
                    </div>
                    <div className={styles.noButton} onClick={() => rejectTheRecBaseline()}>
                        No <CloseIcon style={{ height: "20px", width: "20px" }}></CloseIcon>
                    </div>
                </div>
            </div>)} */}

            {(isAddBaselineMode) && (<div className={styles.promptDiv}>
                <div className={styles.newBaselinePrompt1}>
                    <div className={styles.promptMessage}>
                        Please select a new baseline. Make sure that the new baseline period is not more than 14 days and has at least 10 measurements.
                    </div>
                    {(isAddBaselineModeDone && !isValidating) && <div className={styles.confMessageDiv}>
                        <div className={styles.confMessage}>
                            Do you want to confirm the newly selected Baseline {baselinePayload.length}?
                        </div>
                        <div className={styles.noButton} onClick={() => clearBaselineSelection()}>
                            No <CloseIcon style={{ height: "20px", width: "20px" }}></CloseIcon>
                        </div>
                        <div className={styles.yesButton} onClick={() => validateTheBaseline()}>
                            Yes <CheckIcon style={{ height: "20px", width: "20px" }}></CheckIcon>
                        </div>
                    </div>}
                    {isValidating &&
                        (
                            <div className={styles.validationDiv}>
                                {aidiValidationLoader ? (<><div className={styles.circularProgress1}><CircularProgress thickness={5.0} /></div></>) : (<>
                                    <div className={styles.validationMessage}>
                                        {validationMessage}
                                    </div></>)}
                            </div>
                        )}
                </div>

            </div>)}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={showConfirmBaselinePopUp}
                onClose={(event, reason) => setShowConfirmBaselinePopUp(true)}
                hideBackdrop={true}
            >
                <div className={styles.paperUnassignML}>
                    <div className={styles.menuTitle}>
                        <div className={styles.titleText}>Warning</div>
                        <div>
                            <IconButton aria-label="delete">
                                <RiCloseCircleFill size={29} onClick={() => setShowConfirmBaselinePopUp(false)} style={{ color: "#4E253A" }} />
                            </IconButton>
                        </div>
                    </div>

                    <div className={styles.patientListDiv}>
                        Are you certain about accepting this new baseline?
                        <div className={styles.subWarningDiv}>
                            This selection will be permanent. You cannot undo this action.
                        </div>
                    </div>

                    <div className={styles.menuButtons}>
                        <div className={styles.abortButton} onClick={() => setShowConfirmBaselinePopUp(false)}>
                            No
                        </div>
                        <div className={styles.continueButton} onClick={() => acceptTheRecBaseline()}>
                            Yes
                        </div>
                    </div>
                </div>
            </Modal>

            <div ref={ref} />

        </div>
    );
}