import { useState } from 'react';
import Transport from "../../../../Transport/AidarTransport";
import domainCF from "../../../API/domainCF";

export function useDeleteSubjectVitalsNote() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteSubjectVitalsNote = async (noteId) => {
    setLoading(true);
    try {
      const response = await Transport.genericOperation(
        `${domainCF}/api/deleteSubjectVitalsNote`,
        { 'Content-Type': 'application/json' },
        parseInt(noteId),
        'POST'
      );

      if (response && response.success) {
        setError(null);
        return { success: true };
      } 
    } catch (err) {
      console.error('Error deleting subject vitals note:', err);
      setError('Failed to delete subject vitals note.');
    } finally {
      setLoading(false);
    }
  };

  return { deleteSubjectVitalsNote, loading, error };
}
