import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./AddEditNotes.css";
import ProfileIcon from "../../../../Assets/icons/Light-Profile-Icon.svg";
import CalendarIcon from "../../../../Assets/icons/Calender-icon.svg";
import AddIcon from "../../../../Assets/icons/Add-Icon.svg";




const statusOptions = [
  { label: "CCM", color: "#7C596A" },
  { label: "RPM", color: "#AA346F" },
  { label: "General", color: "#E86EAB" },
];

const TextBoxWithButtons = ({
  newNotes,
  setNewNotes,
  tagName,
  setTagName,
  isBookmarked,
  setIsBookmarked,
  statusOptions,
  readOnly = false,
}) => {
  const handleBookmarkToggle = () => {
    if (!readOnly) {
      setIsBookmarked((prev) => !prev);
    }
  };

  const handleStatusSelect = (status) => {
    if (!readOnly) {
      if (tagName === status) {
        setTagName("");
      } else {
        setTagName(status);
      }
    }
  };

  return (
    <div className="textbox-container">
      <div className="textarea-wrapper">
        <textarea
          value={newNotes}
          onChange={(e) => !readOnly && setNewNotes(e.target.value)}
          placeholder={readOnly?"Describe any relevant information about the patient. E.g., I use Omron blood pressure cuff biweekly.":""}
          maxLength={5000}
          className="note-textarea"
          readOnly={readOnly}
        />
        <div className="character-count">
          {newNotes.length} / 5000 Characters
        </div>
      </div>

      <div className="status-buttons-container">
        {statusOptions.map((status, index) => (
          <button
            key={status.label}
            onClick={() => handleStatusSelect(status.label)}
            className={`status-button ${
              tagName === status.label ? "selected" : ""
            }`}
            style={{
              borderColor: tagName === status.label ? status.color : "#ddd",
              backgroundColor:
                tagName === status.label ? status.color : "#F6EFF4",
              color: tagName === status.label ? "#FFFFFF" : status.color,
              borderRadius:
                index === 0
                  ? "0 0 0 8px"
                  : index === statusOptions.length - 1
                  ? "0"
                  : "0",
            }}
            disabled={readOnly}
          >
            {status.label}
          </button>
        ))}

        <div className="bookmark-button-container">
          <IconButton
            size="small"
            onClick={handleBookmarkToggle}
            style={{
              padding: "5px",
            }}
            disabled={readOnly}
          >
            {isBookmarked ? (
              <BookmarkOutlinedIcon className="bookmark-icon" />
            ) : (
              <BookmarkBorderOutlinedIcon className="bookmark-icon" />
            )}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const NoteForm = ({
  newNotes,
  setNewNotes,
  tagName,
  setTagName,
  isBookmarked,
  setIsBookmarked,
  onSubmit,
  onCancel,
  statusOptions,
  mode,
  expandDefault=true
}) => {
  const handleSubmit = () => {
    if (!tagName) {
      alert("Please select a status to submit.");
      return;
    }

    if (newNotes.trim() === "") {
      alert("Note cannot be empty.");
      return;
    }

    onSubmit({ newNotes, tagName, isBookmarked });
  };

  return (
    <Accordion defaultExpanded={expandDefault} className="custom-accordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className="accordion-summary"
      >
        <img src={AddIcon} className="icon"/>
        <div className="accordion-title">
          {mode === "edit" ? "Edit Note" : "Add New Note"}
        </div>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        <TextBoxWithButtons
          newNotes={newNotes}
          setNewNotes={setNewNotes}
          tagName={tagName}
          setTagName={setTagName}
          statusOptions={statusOptions}
          isBookmarked={isBookmarked}
          setIsBookmarked={setIsBookmarked}
          readOnly={false}
        />

        <div className="action-buttons-container">
          <button onClick={onCancel} className="cancel-button">
            Cancel
          </button>

          <Tooltip
            title={
              !tagName || !newNotes
                ? "Please enter a note and select a tag to submit."
                : ""
            }
            placement="top"
          >
            <span className="submit-button-wrapper">
              <button
                onClick={handleSubmit}
                className="submit-button"
                disabled={!tagName || !newNotes}
              >
                {mode === "edit" ? "Update" : "Submit"}
              </button>
            </span>
          </Tooltip>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const NoteView = ({
  existingNote,
  existingTagName,
  existingIsBookmarked,
  statusOptions,
}) => {
  return (
    <Accordion defaultExpanded className="custom-accordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className="accordion-summary"
      >
       <img  className="icon" src={CalendarIcon}/>
        <div className="accordion-title">View Note</div>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        <TextBoxWithButtons
          newNotes={existingNote}
          setNewNotes={() => {}}
          tagName={existingTagName}
          setTagName={() => {}}
          statusOptions={statusOptions}
          isBookmarked={existingIsBookmarked}
          setIsBookmarked={() => {}}
          readOnly={true}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const AddEditNotes = ({ data = {}, onSave, onClose, patientInfo = {} }) => {
  const isEditMode = !!data?.editNote;
  const isViewMode = !!data?.viewNote;

  const [tagName, setTagName] = useState(
    data?.editNote?.tags || ""
  );
  const [newNotes, setNewNotes] = useState(
    data?.editNote?.note || ""
  );
  const [isBookmarked, setIsBookmarked] = useState(
    data?.editNote?.isBookmarked ==="true" ? true : false
  );

  const handleSave = (noteData) => {
    onSave({
      ...noteData,
      id:data?.editNote?.id,
      mode: isEditMode ? "edit" : "add",
    });
  };

  return (
    <div className="add-edit-notes-container">
      <div className="header">
        <div
          onClick={onClose}
          className="close-button"
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#AA346F";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#FFFFFF";
          }}
        >
          <CloseIcon className="close-icon" />
        </div>

        <div className="profile-details">
          <div className="name-and-id">
            <span className="name">{`${patientInfo?.contactInfo?.firstName ?? ""} ${
              patientInfo?.contactInfo?.lastName ?? ""
            }`.trim()}</span>
            <span className="id">
              ML ID - {patientInfo?.deviceId ?? "None"}
            </span>
          </div>

          <img src={ProfileIcon} alt="Profile Icon" />
        </div>
      </div>

      <div className="notes-title">Notes</div>
      <div className="content-container">
          <NoteForm
            newNotes={newNotes}
            setNewNotes={setNewNotes}
            tagName={tagName}
            setTagName={setTagName}
            isBookmarked={isBookmarked}
            setIsBookmarked={setIsBookmarked}
            onSubmit={handleSave}
            onCancel={onClose}
            statusOptions={statusOptions}
            mode={isEditMode ? "edit" : "add"}
            expandDefault={!isViewMode}
          />

        {isViewMode && (
          <NoteView
            existingNote={data?.viewNote.note}
            existingTagName={data?.viewNote.tags}
            existingIsBookmarked={data?.viewNote?.isBookmarked ==="true" ? true : false}
            statusOptions={statusOptions}
          />
        )}
      </div>
      <button className="view-all-notes-button" onClick={onClose}>
        View All Notes
      </button>
    </div>
  );
};

export default AddEditNotes;
