import React, { Fragment, useContext, useState } from "react";
import styles from "./UnassignMl.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";

// API imports
import PostUnassignMouthLabFromSubject from "../../../../../API/PostUnassignMouthLabFromSubject/PostUnassignMouthLabFromSubject";
import { PatientContext } from "../../../../../Store/PatientDataProvider";
import { useDispatch, useSelector } from "react-redux";
import { getUserRole } from "../../../../../../Reducer/selectors/DashboardSelector";
import { globalConstants } from "../../../../../../Constants/GlobalConstants";
import { setSelectedMlId } from "../../../../../../Actions";
import { getCurrentPatientGUID } from "../../../../../../Reducer/selectors/UtilSelector";

export default function UnassignMl(props) {
  const [subjectId, setSubjectId] = useState(props.currentSubjectId);
  const [deviceId, setDeviceId] = useState(props.currentDeviceId);
  const [isProgress, setIsProgress] = useState(false);
  const [isTimedMessage, setIsTimedMessage] = useState(false);
  const userRole = useSelector(getUserRole);
  const currPatientGUID = useSelector(getCurrentPatientGUID);
  const [response, setResponse] = useState({
    statusCode: 0,
    message: "",
  });
  const dispatch = useDispatch();
  const patientContext = useContext(PatientContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProgress(true);
    PostUnassignMouthLabFromSubject({ deviceId: deviceId }).then((res) => {
      dispatch(setSelectedMlId('None'));
      setResponse({
        statusCode: res.response ? res.response.status : res.error.response.status,
        message: res.response ? res.response.data : res.error.response.data,
      });
      setIsProgress(false);
      setIsTimedMessage(true);
      if (props.unpairAndCloseDrawer) {
        setTimeout(() => {
          setIsTimedMessage(false);
          patientContext.setIsRefresh(true);
          props.unpairAndCloseDrawer();
          props.closeModal();
        }, 500);
      } else {
        setTimeout(() => {
          setIsTimedMessage(false);
          patientContext.setIsRefresh(true);
          props.closeModal();
        }, 500);
      }
    });
  };

  const btnDisabled = isProgress || isTimedMessage;

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className={styles.wrapperDiv}>
          <div className={styles.container}>
            <div className={styles.radioLabelForm}>
              Unassign MouthLab {props.currentDeviceId} from subject {props.currentSubjectLabel}?
            </div>
            {isProgress && (
              <div className={styles.overlayProgressBar}>
                <CircularProgress style={{ color: `#4E253A`, marginLeft: `47%` }} />
                <div className={styles.progressMessage}>Processing, please wait.</div>
              </div>
            )}
            {response.message && (
              <div className={styles.overlayProgressBar}>
                <div className={`${styles.statusMessage} ${response.statusCode === 200 ? styles.smSuccess : styles.smError}`}>{response.message}</div>
              </div>
            )}

            <div className={styles.buttonContainer}>
              <button className={styles.cancelButton} onClick={props.closeModal} disabled={btnDisabled}>
                Cancel
              </button>
              <button type="submit" className={styles.submitButton} disabled={btnDisabled}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
}
