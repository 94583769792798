import { useState, useEffect } from 'react';
import Transport from "../../../../Transport/AidarTransport";
import domainCF from "../../../../Components/API/domainCF";

export function useBaselineData(subjectId, startDate, endDate) {
  const [data, setData] = useState({
    status: "",
    success: false,
    baselines: [],
    validDays: [],
    maxParamerters: 3,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBaselineData = async () => {
      console.log("Fetching baseline data for range:", startDate, endDate);
      setLoading(true);
      try {
        const response = await Transport.genericOperation(
          `${domainCF}/api/getUpdateBaselineInfo?subjectId=${subjectId}&startDate=${startDate}&endDate=${endDate}`, 
          {},
          {},
          'GET'
        );

        if (response && response.success) {
          setData(response);
          setError(null);
        } else {
          throw new Error('Failed to fetch baseline data.');
        }
      } catch (err) {
        console.error('Error fetching baseline data:', err);
        setError('Failed to load baseline data.');
      }
      setLoading(false);
    };

    fetchBaselineData();
  }, [subjectId, startDate, endDate]);

  return { data, loading, error };
}
export function useUpdateBaseline() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateBaseline = async (subjectId, baselineDate, paramStatus, note,userId) => {
    setLoading(true);
    try {
      const response = await Transport.genericOperation(
        `${domainCF}/api/addNewBaselineBySubjectAndDate`, 
        { 'Content-Type': 'application/json' },
        JSON.stringify({ subjectId, baselineDate, paramStatus, note, userId }),
        'POST'
      );

      if (response && response.success) {
        setError(null);
        return { success: true };
      } else {
        throw new Error('Failed to update baseline.');
      }
    } catch (err) {
      console.error('Error updating baseline:', err);
      setError('Failed to update baseline.');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { updateBaseline, loading, error };
}
