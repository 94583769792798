import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Modal,
  IconButton,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format, subDays } from "date-fns";
import useStyles from "./useUpdateBaseLineStyle";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useBaselineData, useUpdateBaseline } from "./useBaselineData";
import { generateParameterString } from "../../../../Utils/GlobalFunctions"
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import { getCurrentPatientGUID, getCurrentPatientSubjectID } from "../../../../Reducer/selectors/UtilSelector";


import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


export default function UpdateBaselineModal({ open, onClose, fetchChartData }) {
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    "& .MuiTooltip-tooltip": {
      backgroundColor: "#ffffff",
      color: "rgba(0, 0, 0, 0.87)",
      borderRadius: "8px",
      textAlign: "center",
      minWidth: "190px",
      padding: "0px",
    },
    "& .MuiTooltip-arrow": {
      color: "#ffffff",
    },
  });

  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(null);
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [availableParameters, setAvailableParameters] = useState([]);
  const calendarContainerRef = useRef(null);
  const [selectedParameters, setSelectedParameters] = useState([]);
  const userId = useSelector(getUserProfile).id;
  const subjectId = useSelector(getCurrentPatientSubjectID);
  const subjectGuid = useSelector(getCurrentPatientGUID);
  const navigate = useNavigate();

  const [notes, setNotes] = useState("");
  const [formErrors, setFormErrors] = useState({
    date: "",
    parameters: "",
    notes: "",
  });

  const [activeStartDate, setActiveStartDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth() - 1, 1);
  });

  const startDate = useMemo(() => {
    return format(
      new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1),
      "yyyy-MM-dd"
    );
  }, [activeStartDate]);

  const endDate = useMemo(() => {
    return format(
      new Date(
        activeStartDate.getFullYear(),
        activeStartDate.getMonth() + 2,
        0
      ),
      "yyyy-MM-dd"
    );
  }, [activeStartDate]);

  const { data, loading, error } = useBaselineData(subjectId, startDate, endDate);
  const {
    updateBaseline,
    loading: updating,
    error: updateError,
  } = useUpdateBaseline();

  const isProcessing = loading || updating;

  // Update activeStartDate only when month changes
  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setActiveStartDate((prevDate) => {
      if (
        prevDate.getMonth() !== activeStartDate.getMonth() ||
        prevDate.getFullYear() !== activeStartDate.getFullYear()
      ) {
        return activeStartDate;
      }
      return prevDate;
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (calendarContainerRef.current) {
        const monthViews = calendarContainerRef.current.querySelectorAll(
          ".react-calendar__month-view"
        );
        monthViews.forEach((view, index) => {
          const monthDate = new Date(
            activeStartDate.getFullYear(),
            activeStartDate.getMonth() + index,
            1
          );
          view.setAttribute("data-heading", format(monthDate, "MMMM yyyy"));
        });
      }
    }, 0);
    return () => clearTimeout(timer);
  }, [activeStartDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormErrors((prevErrors) => ({ ...prevErrors, date: "" }));

    // Calculate the last 9 days
    const datesToHighlight = [];
    for (let i = 1; i <= 9; i++) {
      const newDate = subDays(date, i);
      datesToHighlight.push(format(newDate, "yyyy-MM-dd"));
    }
    setHighlightedDates(datesToHighlight);

    // Set available parameters for the selected date
    const dateStr = format(date, "yyyy-MM-dd");
    const validDay = data?.validDays.find((day) => day.date === dateStr);
    const parametersForDate = validDay?.parameters || [];
    setAvailableParameters(parametersForDate);
    setSelectedParameters([]); // Reset selected parameters when date changes
  };

  const handleParameterToggle = (paramName) => {
    let updatedParameters;
    if (selectedParameters.includes(paramName)) {
      updatedParameters = selectedParameters.filter((p) => p !== paramName);
    } else {
      updatedParameters = [...selectedParameters, paramName];
    }
    setSelectedParameters(updatedParameters);
    if (updatedParameters.length === data?.maxParamerters) {
      setFormErrors((prevErrors) => ({ ...prevErrors, parameters: "" }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        parameters: `Please select exactly ${data?.maxParamerters} parameters.`,
      }));
    }
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
    setFormErrors((prevErrors) => ({ ...prevErrors, notes: "" }));
  };

  const handleApply = async () => {
    let hasErrors = false;
    const errors = { date: "", parameters: "", notes: "" };

    if (!selectedDate) {
      errors.date = "Please select a date.";
      hasErrors = true;
    }
    if (selectedParameters.length !== data?.maxParamerters) {
      errors.parameters = `Please select exactly ${data?.maxParamerters} parameters.`;
      hasErrors = true;
    }
    if (!notes.trim()) {
      errors.notes = "Notes field is required.";
      hasErrors = true;
    }

    setFormErrors(errors);

    if (hasErrors) return;

    try {
      await updateBaseline(subjectId, format(selectedDate, "yyyy-MM-dd"), generateParameterString(availableParameters, selectedParameters), notes, userId);
      onClose();
      fetchChartData();
    } catch (err) {
      alert("An error occurred while updating the baseline.");
    }
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.modalOverlay}>
      <div className={classes.modalContent}>
        {isProcessing && (
          <div className={classes.loaderOverlay}>
            <CircularProgress />
          </div>
        )}

        <div className={classes.modalHeader}>
          <h2 className={classes.modalTitle}>Select New Baseline</h2>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <div
          style={{
            padding: "10px 20px 20px 20px",
            overflow: "auto",
            opacity: isProcessing ? 0.5 : 1,
          }}
        >
          <div className={classes.instructions}>
            A new baseline will be calculated from 10 days of data, please select the end date for the new baseline.
          </div>

          <div
            className={classes.calendarContainer}
            ref={calendarContainerRef}
          >
            {error ? (
              <div style={{ color: "red", textAlign: "center" }}>
                Error loading calendar data.
              </div>
            ) : (
              <Calendar
                onChange={handleDateChange}
                value={selectedDate}
                selectRange={false}
                minDetail="month"
                maxDate={new Date()}
                defaultActiveStartDate={activeStartDate}
                onActiveStartDateChange={handleActiveStartDateChange}
                showDoubleView={true}
                prev2Label={null}
                next2Label={null}
                tileDisabled={({ date }) => {
                  if (!data?.validDays) return false;
                  const dateStr = format(date, "yyyy-MM-dd");
                  const isValidDate = data.validDays.some(
                    (validDay) => validDay.date === dateStr
                  );
                  return !isValidDate;
                }}
                tileContent={({ date, view }) => {
                  if (view === "month" && data?.baselines) {
                    const baseline = data.baselines.find((baseline) => {
                      const startDate = new Date(baseline.start);
                      const endDate = new Date(baseline.end);
                      endDate.setDate(endDate.getDate() + 1);
                      return date >= startDate && date <= endDate;
                    });
                    if (baseline) {
                      return (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundColor: baseline.color + '4D',
                            zIndex: -1,
                            opacity: 0.6,
                            color: "white !important",
                          }}
                        />
                      );
                    }
                  }
                  return null;
                }}
                tileClassName={({ date, view }) => {
                  let classesToAdd = [classes.calendarTile];
                  if (view === "month") {
                    const dateStr = format(date, "yyyy-MM-dd");
                    if (
                      selectedDate &&
                      dateStr === format(selectedDate, "yyyy-MM-dd")
                    ) {
                      classesToAdd.push(classes.selectedDate);
                    }
                    if (highlightedDates.includes(dateStr)) {
                      classesToAdd.push(classes.highlightedDate);
                    }
                  }
                  return classesToAdd;
                }}
              />
            )}
            {formErrors.date && (
              <div
                style={{
                  color: "red",
                  textAlign: "left",
                  marginTop: "8px",
                  fontSize: "12px",
                  width: "100%",
                  marginLeft: "14px",
                  marginBottom: "10px"
                }}
              >
                {formErrors.date}
              </div>
            )}
          </div>
          <div className={classes.interactiveButtons}>
            {data?.baselines?.map((baseline, index) => (
              <button
                key={index}
                style={{ backgroundColor: baseline.color + '4D', opacity: "0.6", fontFamily: "GTWalsheimProRegular", color: "black" }}
              >
                {baseline.name}
              </button>
            ))}
          </div>

          <div className={classes.parameterSelection}>
            <div className={classes.paramSubtitle}>
              Select {data?.maxParamerters} Key Parameters
              <CustomTooltip
                title={
                  <React.Fragment>
                    <div style={{ display: "flex", flexDirection: "column", borderRadius: "11px" }}>
                      <div
                        style={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          backgroundColor: "#4E253A",
                          fontSize: "13px",
                          fontWeight: "700",
                          color: "white",
                          borderTopLeftRadius: "11px",
                          borderTopRightRadius: "11px",
                          fontFamily: "GTWalsheimProRegular"

                        }}
                      >
                        Aidar Index
                      </div>
                      <div
                        className={classes.parameterButtons}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          color: "#4E253A",
                        }}
                      >
                        <div>
                          <button style={{ marginRight: "10px" }}>- -</button>
                          Available to select
                        </div>
                        <div>
                          <button
                            disabled={true}
                            style={{ marginRight: "10px" }}
                          >
                            - -
                          </button>
                          Unavailable to select
                        </div>
                        <div>
                          <button
                            className="selected"
                            style={{ marginRight: "10px" }}
                          >
                            - -
                          </button>
                          Selected
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                }
                arrow
              >
                <InfoIcon
                  style={{
                    height: "16px",
                    width: "16px",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                />
              </CustomTooltip>
            </div>
            {error ? (
              <div style={{ color: "red", textAlign: "left", fontSize: "12px" }}>
                Error loading parameters.
              </div>
            ) : (
              <div className={classes.parameterButtons}>
                {availableParameters.length === 0 && (
                  <div style={{ color: "#888", marginTop: "2px", fontSize: "12px" }}>
                    Select a date to see available parameters.
                  </div>
                )}
                {availableParameters.map((paramObj) => (
                  <button
                    key={paramObj.name}
                    className={
                      selectedParameters.includes(paramObj.name)
                        ? "selected"
                        : ""
                    }
                    onClick={() => handleParameterToggle(paramObj.name)}
                    disabled={
                      (!selectedParameters.includes(paramObj.name) &&
                        selectedParameters.length >= data?.maxParamerters) ||
                      !paramObj.enabled
                    }
                  >
                    {paramObj.name}
                  </button>
                ))}
              </div>
            )}
            {formErrors.parameters && (
              <div
                style={{
                  color: "red",
                  textAlign: "left",
                  marginTop: "8px",
                  marginLeft: "5px",
                  fontSize: "12px"
                }}
              >
                {formErrors.parameters}
              </div>
            )}
          </div>

          <div className={classes.parameterSelection}>
            <div className={classes.paramSubtitle}>Enter Notes</div>
            <div className={classes.notesWrapper}>
              <TextField
                style={{
                  border: "none",
                  backgroundColor: "white",
                  borderBottomRightRadius: "5px",
                  borderBottomLeftRadius: "5px",
                  fontSize: "12px !important"
                }}
                placeholder="Enter any relevant notes or information."
                multiline
                minRows={3}
                maxRows={3}
                fullWidth
                value={notes}
                onChange={handleNotesChange}
                inputProps={{
                  maxLength: 300
                }}
                className={classes.textFieldNoBorder}
              />
              <div className={classes.notesTextCount}>
                <div className={classes.counter}>
                  {notes.length}/300
                </div>
              </div>
            </div>
            {formErrors.notes && (
              <div
                style={{
                  color: "red",
                  textAlign: "left",
                  marginTop: "8px",
                  marginLeft: "5px",
                  fontSize: "12px",
                }}
              >
                {formErrors.notes}
              </div>
            )}
          </div>

          <div className={classes.modalActions}>
            <button className={classes.cancelButton} onClick={onClose}>
              Cancel
            </button>
            <button
              className={classes.applyButton}
              onClick={handleApply}
              disabled={isProcessing}
            >
              {updating ? "Applying..." : "Apply"}
            </button>
          </div>
          {updateError && (
            <div
              style={{ color: "red", textAlign: "center", marginTop: "10px" }}
            >
              Error updating baseline.
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
